<template>
  <div class="lessonModel S13" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="sentenceWrap">
          <div class="audio" :class="{active: audioPlaying && !['correct', 'wrong'].includes(checkStatus)}"></div>
          <div class="trans">{{trans}}</div>
        </div>
        <div class="stems" :class="{active: stemsActive}" v-show="keyboardMode">
          <template v-if="!stems.length">
            <div class="placeholder">{{$t('course.write_down_the_sentence')}}</div>
          </template>
          <template v-else v-for="(stem, i) in stems">
            <div class="stem" @click.stop="stemChooseHandle(stem, i)" :key="i">{{stem}}</div>
          </template>
        </div>
      </div>

      <div class="answerWrap">
        <template v-if="keyboardMode">
          <div class="optionsWrap">
            <template v-for="(op, i) in options">
              <div class="option" :class="[{active: op.active}, op.status]" @click.stop="optionChooseHandle(op, i)" :key="i">
                <div class="optionName">{{op.wd}}</div>
              </div>
            </template>
          </div>
        </template>

        <template v-else>
          <div class="writeWrap">
            <textarea v-model="textarea" ref="textarea" class="textarea" @click.stop :placeholder="`${$t('course.write_down_the_sentence')}\n${$t('course.please_install_the_keyboard_of_the_language_first')}`"></textarea>
          </div>
        </template>

        <div class="divider"></div>

        <div class="operationWrap">
          <div class="leftWrap">
            <div class="iconWrap keyboardIcon" @click.stop="keyboardHandle"><svg t="1679466831708" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2495" width="38" height="38"><path d="M896 256v512H128V256h768zM298.666667 469.333333H213.333333v85.333334h85.333334v-85.333334z m128 0H341.333333v85.333334h85.333334v-85.333334z m128 0h-85.333334v85.333334h85.333334v-85.333334z m128 0h-85.333334v85.333334h85.333334v-85.333334z m128 0h-85.333334v85.333334h85.333334v-85.333334zM341.333333 341.333333H213.333333v85.333334h128V341.333333z m128 0H384v85.333334h85.333333V341.333333z m128 0h-85.333333v85.333334h85.333333V341.333333z m213.333334 0h-170.666667v85.333334h170.666667V341.333333zM341.333333 597.333333H213.333333v85.333334h128v-85.333334z m298.666667 0H384v85.333334h256v-85.333334z m170.666667 0h-128v85.333334h128v-85.333334z" fill="#A6BAD7" p-id="2496"></path></svg></div>
          </div>
          <div class="rightWrap" v-show="keyboardMode">
            <div class="iconWrap eyeIcon" :class="{active: eyeActive}" @click.stop="eyeHandle" v-show="eyeVisible"><svg t="1679467135642" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3885" id="mx_n_1679467135643" width="34" height="34"><path d="M512 192c156.448 0 296.021333 98.730667 418.410667 291.605333a52.938667 52.938667 0 0 1 0 56.789334C808.021333 733.269333 668.448 832 512 832c-156.448 0-296.021333-98.730667-418.410667-291.605333a52.938667 52.938667 0 0 1 0-56.789334C215.978667 290.730667 355.552 192 512 192z m0 128c-106.037333 0-192 85.962667-192 192s85.962667 192 192 192 192-85.962667 192-192-85.962667-192-192-192z m0 320c70.688 0 128-57.312 128-128s-57.312-128-128-128-128 57.312-128 128 57.312 128 128 128z" fill="#004E94" p-id="3886"></path></svg></div>
            <div class="iconWrap backspaceIcon" @click.stop="backspaceHandle"><svg t="1681973458713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3724" width="28" height="28"><path d="M950.637714 219.538286v584.923428H393.508571l-290.596571-291.547428 292.425143-293.376h555.264zM1023.634286 146.285714H365.421714L0 512.914286 363.593143 877.714286H1024V146.285714h-0.365714z" fill="#004E94" p-id="3725"></path><path d="M534.308571 318.171429l-51.565714 51.565714 336.091429 336.091428 51.565714-51.565714z" fill="#004E94" p-id="3726"></path><path d="M818.834286 318.171429l-336.091429 336.091428 51.565714 51.565714 336.091429-336.091428z" fill="#004E94" p-id="3727"></path></svg></div>
          </div>
        </div>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">
        <template v-for="(char, i) in chars">
          <div class="char" :class="{ wrong: char.wrong }" :key="i">{{char.char}}</div>
        </template>
      </div>
    </check-wrap>
  </div>
</template>

<script>
  import constants from '@/utils/constants'
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        answerStr: '',
        chars: [],
        stems: [],
        options: [],
        choosedOptionIndexs: [],
        keyboardMode: true,
        eyeVisible: true,
        eyeActive: false,
        stemsActive: false,
        textarea: ''
      }
    },
    watch: {
      stems: 'checkStatusHandle',
      textarea: 'checkStatusHandle',
      keyboardMode: 'checkStatusHandle'
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    methods: {
      init() {
        let { id, tr, wls } = this.lessonData
        let answerStr = ''
        let optionsStr = ''

        for (let i = 0; i < wls.length; i ++) {
          let word = wls[i]
          if (word.ty !== '1') {
            answerStr += word.wd + ' '
            optionsStr += word.wd.split('').sort(() => Math.random() - 0.5).join('') + ' '
          }
        }

        this.id = id
        this.trans = tr
        this.answerStr = answerStr.trim()
        this.chars = answerStr.split('').map(char => {
          return { char }
        })
        this.options = optionsStr.trim().split('').map(item => {
          return { wd: item }
        })
      },
      optionChooseHandle(op, index) {
        if (!op.active) {
          op.active = true
          this.choosedOptionIndexs.push(index)
          if (!this.stems.length) {
            this.stems.push(op.wd.toUpperCase())
          } else {
            this.stems.push(op.wd)
          }
        }
      },
      keyboardHandle() {
        this.keyboardMode = !this.keyboardMode
        if (!this.keyboardMode) {
          this.$nextTick(() => {
            this.$refs.textarea.focus()
          })
        }
      },
      eyeHandle() {
        let that = this
        let currentStems = this.stems.join('')
        if (this.answerStr.toLowerCase().indexOf(currentStems.toLowerCase()) === 0) {
          let nextChar = this.answerStr.charAt(currentStems.length)
          for (let i = 0; i < this.options.length; i ++) {
            let option = this.options[i]
            if (!option.active && (option.wd === nextChar)) {
              this.$set(this.options[i], 'status', 'remind')
              setTimeout(() => {
                that.$set(this.options[i], 'status', '')
              }, 1000)
              return
            }
          }
        } else {
          this.eyeActive = true
          this.stemsActive = true
          setTimeout(() => {
            that.eyeActive = false
            that.stemsActive = false
          }, 1000)
        }
      },
      backspaceHandle() {
        if (this.stems.length) {
          let optionIndex = this.choosedOptionIndexs[this.choosedOptionIndexs.length - 1]
          this.options[optionIndex].active = false
          this.choosedOptionIndexs.pop()
          this.stems.pop()
        }
      },
      playHandle() {
        this.$refs.checkWrap.stopVideo()
        this.playAudio('s', this.id)
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          let userAnswer = ''
          if (this.keyboardMode) {
            userAnswer = this.stems.join('').toLowerCase()
          } else {
            userAnswer = this.textarea.trim().toLowerCase().replace(constants.allPuncRegex, '')
          }

          for (let i = 0; i < this.chars.length; i ++) {
            let char = this.chars[i]
            let userAnswerChar = userAnswer[i]
            if (userAnswerChar) {
              if (userAnswerChar.toLowerCase() !== char.char.toLowerCase()) {
                char.wrong = true
              }
            } else {
              char.wrong = true
            }
            if (i === 0) {
              char.char = char.char.toUpperCase()
            }
          }

          if (userAnswer === this.answerStr.toLowerCase()) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
        }
      },
      checkStatusHandle() {
        if (this.keyboardMode) {
          if (this.stems.length) {
            this.checkStatus = 'checkReady'
          } else {
            this.checkStatus = 'default'
          }
          
          if (this.stems.length === this.options.length) {
            this.eyeVisible = false
          } else {
            this.eyeVisible = true
          }
        } else {
          let userInput = this.textarea.trim()
          if (userInput) {
            this.checkStatus = 'checkReady'
          } else {
            this.checkStatus = 'default'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S13 {
    .lessonWrap {
      .questionWrap {
        width: 100%;
        margin-bottom: 60px;
        .sentenceWrap {
          display: flex;
          align-items: center;
        }
        .stems {
          margin-top: 50px;
          border-bottom: 1px solid #C4C9D8;
          height: 32px;
          min-width: 600px;
          max-width: 100%;
          justify-content: center;
          &.active {
            border-bottom: 1px solid #EE5151;
            color: #EE5151;
          }
          .stem {
            margin: 0;
            white-space: pre;
          }
          .placeholder {
            font-weight: 400;
            font-size: 18px;
            color: #9090A2;
          }
        }
      }
      .answerWrap {
        box-sizing: border-box;
        padding: 20px;
        padding-bottom: 12px;
        width: 590px;
        background: #E0E9F6;
        border-radius: 20px;
        .divider {
          margin: 20px 0;
          margin-bottom: 12px;
          height: 1px;
        }
        .optionsWrap {
          justify-content: flex-start;
          align-content: start;
          overflow: auto;
          min-height: 160px;
          max-height: 200px;
          .option {
            margin: 5px;
            padding: 8px;
            min-width: 45px;
            min-height: 43px;
            border-radius: 10px;
            font-size: 18px;
            &:hover {
              background: #C3D6F8;
            }
            &.active {
              visibility: hidden;
              cursor: default;
            }
            &.remind {
              animation: remind 1s ease;
              @keyframes remind {
                50% {
                  background: #004E94;
                  color: #FFFFFF;
                }
              }
            }
          }
        }
        .writeWrap {
          .textarea {
            box-sizing: border-box;
            border: none;
            outline: none;
            border-radius: 10px;
            padding: 12px;
            width: 100%;
            height: 180px;
            font-size: 20px;
            resize: none;
          }
        }
        .operationWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .leftWrap, .rightWrap {
            display: flex;
            .iconWrap {
              display: flex;
              align-items: center;
              cursor: pointer;
              &.eyeIcon {
                margin-right: 24px;
                &.active {
                  animation: eyeActive 1s linear;
                  @keyframes eyeActive {
                    10% {
                      transform: translateX(8px);
                    }
                    20% {
                      transform: translateX(-6px);
                    }
                    30% {
                      transform: translateX(5px);
                    }
                    40% {
                      transform: translateX(-5px);
                    }
                    50%, 100% {
                      transform: translateX(0px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .S13 {
      .lessonWrap {
        .questionWrap {
          margin-bottom: 20px;
          .stems {
            margin-top: 20px;
            min-width: 100%;
          }
        }
        .answerWrap {
          width: 100%;
        }
      }
    }
  }
</style>