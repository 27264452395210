<template>
  <div class="lessonFinish">
    <div class="contentWrap">
      <div class="imageWrap">
        <img src="@/assets/images/courses/lesson/finish/finish.png" alt="finish">
      </div>

      <div class="scoreWrap">
        <div class="item">
          <div class="value">
            <img v-if="score >= 85" src="@/assets/images/courses/lesson/finish/scorePerfect.png">
            <img v-else-if="score >= 60" src="@/assets/images/courses/lesson/finish/scoreGood.png">
            <img v-else src="@/assets/images/courses/lesson/finish/scoreWeak.png">
            <div class="valText">{{score}}%</div>
          </div>
          <div class="key">{{$t('course.accuracy')}}</div>
        </div>
        <div class="item">
          <div class="value">
            <img src="@/assets/images/courses/lesson/finish/xp.png">
            <div class="valText">{{xp}}</div>
          </div>
          <div class="key">{{$t('main.experience')}}</div>
        </div>
      </div>

      <div class="lessonVideoWrap" v-if="currentKnowledge">
        <video :src="getVideoUrl(currentKnowledge)" ref="lessonVideo" @click="videoPauseHandle" @play="videoStatus = 'play'" @ended="videoEndHandle" autoplay></video>
        <img v-show="videoStatus === 'pause'" class="videoPlayIcon" @click="videoPauseHandle" src="@/assets/images/clips/lesson/play.png">
        <div class="videoStem" :class="currentKnowledge.status">{{currentKnowledge.stem}}</div>
        <div class="videotrans">{{currentKnowledge.tr}}</div>
        <div class="videoIndex">{{`${videoIndex + 1}/${knowledgeArr.length}`}}</div>
      </div>

      <div class="masteryWrap">
        <!-- <div class="headerWrap">
          <div class="headerName">Mastery</div>
          <el-popover
            placement="bottom-end"
            width="300"
            trigger="hover">
            <div class="masteryExplain">
              <div class="header">Mastery:按照答题正确率计算</div>
              <div class="content">Perfect≥80<br>60≤Good&lt;80<br>Weak&lt;60</div>
            </div>
            <div class="iconWrap" slot="reference">
              <img width="18" src="@/assets/images/courses/lesson/finish/masteryExplain.png">
            </div>
          </el-popover>
        </div> -->
        <div class="knowledgeWrap perfectWrap" :class="{active: activeMastery === 1}">
          <div class="knowledgeHeader" @click="foldHandle(1)">
            <div class="percent" :style="{width: Math.round((perfectArr.length / knowledgesNum) * 100) + '%'}"></div>
            <div class="knowledgeHeaderWrap">
              <div class="headerText">{{$t('course.perfect')}}</div>
              <div class="knowledgeNum">{{perfectArr.length}}</div>
              <div class="corner"><svg t="1680856007303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5584" width="28" height="28"><path d="M512.726547 675.318646c-8.063653 0-15.790638-3.245927-21.435195-9.006118L231.175103 400.906809c-11.603269-11.837606-11.410887-30.840402 0.427742-42.442648 11.837606-11.601222 30.841426-11.410887 42.442648 0.427742l238.681054 243.534596L751.407602 358.891903c11.601222-11.839653 30.602995-12.033058 42.442648-0.427742 11.839653 11.603269 12.031011 30.605042 0.427742 42.442648L534.161742 666.312528C528.517185 672.072719 520.791224 675.318646 512.726547 675.318646z" fill="#272636" p-id="5585"></path></svg></div>
            </div>
          </div>
          <div class="knowledgeContent">
            <template v-for="(knowledge, i) in perfectArr">
              <div class="knowledgeItem" @click="knowledgeClickHandle(knowledge)" :key="i">
                <div class="knowledgeStem">{{knowledge.stem}}</div>
                <div class="knowledgeTrans">{{knowledge.tr}}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="knowledgeWrap goodWrap" :class="{active: activeMastery === 2}">
          <div class="knowledgeHeader" @click="foldHandle(2)">
            <div class="percent" :style="{width: Math.round((goodArr.length / knowledgesNum) * 100) + '%'}"></div>
            <div class="knowledgeHeaderWrap">
              <div class="headerText">{{$t('course.good')}}</div>
              <div class="knowledgeNum">{{goodArr.length}}</div>
              <div class="corner"><svg t="1680856007303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5584" width="28" height="28"><path d="M512.726547 675.318646c-8.063653 0-15.790638-3.245927-21.435195-9.006118L231.175103 400.906809c-11.603269-11.837606-11.410887-30.840402 0.427742-42.442648 11.837606-11.601222 30.841426-11.410887 42.442648 0.427742l238.681054 243.534596L751.407602 358.891903c11.601222-11.839653 30.602995-12.033058 42.442648-0.427742 11.839653 11.603269 12.031011 30.605042 0.427742 42.442648L534.161742 666.312528C528.517185 672.072719 520.791224 675.318646 512.726547 675.318646z" fill="#272636" p-id="5585"></path></svg></div>
            </div>
          </div>
          <div class="knowledgeContent">
            <template v-for="(knowledge, i) in goodArr">
              <div class="knowledgeItem" @click="knowledgeClickHandle(knowledge)" :key="i">
                <div class="knowledgeStem">{{knowledge.stem}}</div>
                <div class="knowledgeTrans">{{knowledge.tr}}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="knowledgeWrap weakWrap" :class="{active: activeMastery === 3}">
          <div class="knowledgeHeader" @click="foldHandle(3)">
            <div class="percent" :style="{width: Math.round((weakArr.length / knowledgesNum) * 100) + '%'}"></div>
            <div class="knowledgeHeaderWrap">
              <div class="headerText">{{$t('course.weak')}}</div>
              <div class="knowledgeNum">{{weakArr.length}}</div>
              <div class="corner"><svg t="1680856007303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5584" width="28" height="28"><path d="M512.726547 675.318646c-8.063653 0-15.790638-3.245927-21.435195-9.006118L231.175103 400.906809c-11.603269-11.837606-11.410887-30.840402 0.427742-42.442648 11.837606-11.601222 30.841426-11.410887 42.442648 0.427742l238.681054 243.534596L751.407602 358.891903c11.601222-11.839653 30.602995-12.033058 42.442648-0.427742 11.839653 11.603269 12.031011 30.605042 0.427742 42.442648L534.161742 666.312528C528.517185 672.072719 520.791224 675.318646 512.726547 675.318646z" fill="#272636" p-id="5585"></path></svg></div>
            </div>
          </div>
          <div class="knowledgeContent">
            <template v-for="(knowledge, i) in weakArr">
              <div class="knowledgeItem" @click="knowledgeClickHandle(knowledge)" :key="i">
                <div class="knowledgeStem">{{knowledge.stem}}</div>
                <div class="knowledgeTrans">{{knowledge.tr}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="btnWrap">
      <div class="btnWrapContent">
        <div class="finishBtn" @click="finishHandle">{{$t('course.FINISH')}}</div>
        <!-- <div v-if="isMember && starNum >= 3" class="nextBtn" @click="nextLessonHandle">{{$t('course.next_lesson')}}</div> -->
      </div>
    </div>

    <buy-window :maskShow="maskShow" @cancel="cancelHandle"></buy-window>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import Audio from '@/utils/audioUtil'
  import constants from '@/utils/constants'
  import { updateHistoryData, updateCoursesProgressData } from '@/utils/dataUpdateUtil'
  import buyWindow from '@/components/BuyWindow.vue'

  export default {
    data() {
      return {
        wordsNum: 0,
        sentencesNum: 0,
        knowledgesNum: 0,
        starNum: 0,
        score: 0,
        xp: 0,
        knowledgeArr: [],
        perfectArr: [],
        goodArr: [],
        weakArr: [],
        activeMastery: 0,
        maskShow: false,
        videoIndex: 0,
        videoStatus: 'pause'
      }
    },
    components: {
      buyWindow
    },
    computed: {
      ...Vuex.mapGetters(['coursesUnitsData', 'isMember', 'learnLanguage']),
      currentKnowledge() {
        return this.knowledgeArr[this.videoIndex]
      }
    },
    props: ['lessonsFinishData', 'coursesLessonInfo', 'duration'],
    mounted() {
      this.init()
      this.updateData()
    },
    methods: {
      init() {
        let knowledges = {}
        let wordIDs = new Set()
        let sentenceIDs = new Set()

        for (let lesson of this.lessonsFinishData) {
          let { data, result } = lesson
          let { id, kty, tr, st, wd } = data
          id = String(id)
          let knowledgeKey = kty + ':' + id

          if (kty === '0') {
            wordIDs.add(id)
          } else if (kty === '1') {
            sentenceIDs.add(id)
          }

          if (knowledges[knowledgeKey]) {
            knowledges[knowledgeKey].allNum += 1
            if (result) {
              knowledges[knowledgeKey].correctNum += 1
              knowledges[knowledgeKey].scores.push(1)
            } else {
              knowledges[knowledgeKey].wrongNum += 1
              knowledges[knowledgeKey].scores.push(-1)
            }
          } else {
            knowledges[knowledgeKey] = {
              allNum: 1,
              correctNum: result ? 1 : 0,
              wrongNum: !result ? 1 : 0,
              scores: [result ? 1 : -1],
              stem: st || wd,
              id,
              kty,
              tr,
            }
          }
        }

        this.wordsNum = wordIDs.size
        this.sentencesNum = sentenceIDs.size
        this.knowledgesNum = this.wordsNum + this.sentencesNum
        this.starNum = this.getStarNum(knowledges)
        this.MasteryHandle(knowledges)
      },
      getStarNum(knowledges) {
        let starNum = 0
        let score = 0
        let totalScore = 0

        for (let knowledgeKey in knowledges) {
          let knowledge = knowledges[knowledgeKey]
          score += this.getKnowledgeScore(knowledge)
          totalScore += 1
        }

        let base = score / totalScore

        if (base === -1) {
          starNum = 0
        } else if (base <= -0.6) {
          starNum = 1
        } else if (base <= -0.2) {
          starNum = 2
        } else if (base <= 0.2) {
          starNum = 3
        } else if (base <= 0.6) {
          starNum = 4
        } else {
          starNum = 5
        }
        
        return starNum
      },
      // 获取一个知识点的记忆强度
      getKnowledgeScore(knowledge) {
        let score = 0
        let scoresArr = knowledge.scores.slice(0, 3)

        if (scoresArr.length === 1) {
          score += scoresArr[0] * 1.0
        } else if (scoresArr.length === 2) {
          score += scoresArr[0] * 0.4
          score += scoresArr[1] * 0.6
        } else if (scoresArr.length === 3) {
          score += scoresArr[0] * 0.2
          score += scoresArr[1] * 0.3
          score += scoresArr[2] * 0.5
        }
        
        return score
      },
      MasteryHandle(knowledges) {
        let knowledgeArr = []
        let perfectArr = []
        let goodArr = []
        let weakArr = []
        let knowledgesAllNum = 0
        let knowledgesCorrectNum = 0
        let xpBase = this.coursesLessonInfo.redo ? 5 : 10

        for (let knowledgeKey in knowledges) {
          let knowledgeData = knowledges[knowledgeKey]
          let { allNum, correctNum } = knowledgeData
          knowledgesAllNum += allNum
          knowledgesCorrectNum += correctNum

          if (correctNum / allNum >= 0.8) {
            knowledgeData.status = 'perfect'
            perfectArr.push(knowledgeData)
          } else if ((correctNum / allNum < 0.8) && (correctNum / allNum >= 0.6)) {
            knowledgeData.status = 'good'
            goodArr.push(knowledgeData)
          } else {
            knowledgeData.status = 'weak'
            weakArr.push(knowledgeData)
          }
          knowledgeArr.push(knowledgeData)
        }

        this.knowledgeArr = knowledgeArr
        this.perfectArr = perfectArr
        this.goodArr = goodArr
        this.weakArr = weakArr

        this.score = Math.round((knowledgesCorrectNum / knowledgesAllNum) * 100)
        this.xp = Math.round((knowledgesCorrectNum / knowledgesAllNum) * xpBase)
      },
      getVideoUrl(knowledge) {
        let id = knowledge.id
        let type = knowledge.kty === '1' ? 's' : 'w'
        let videoURL = constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
          '/lesson_f' +
          constants.langsData[this.learnLanguage]['courseMediaName'] +
          `-f-${type}-${id}.mp4`
        return videoURL
      },
      videoPauseHandle() {
        if (this.videoStatus === 'play') {
          this.videoStatus = 'pause'
          this.$refs.lessonVideo.pause()
        } else {
          this.videoStatus = 'play'
          this.$refs.lessonVideo.play()
        }
      },
      videoEndHandle() {
        setTimeout(() => {
          if (this.videoIndex === this.knowledgeArr.length - 1) {
            this.videoIndex = 0
          } else {
            this.videoIndex ++
          }
        }, 300)
      },
      foldHandle(index) {
        if (this.activeMastery === index) {
          this.activeMastery = 0
        } else {
          this.activeMastery = index
        }
      },
      knowledgeClickHandle(knowledge) {
        let { kty, id } = knowledge
        let type = kty === '1' ? 's' : 'w'
        this.playAudio(type, id)
      },
      playAudio(type, id) {
        let audioUrl =
          constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
          'lesson_f/' +
          constants.langsData[this.learnLanguage]['courseMediaName'] +
          `-f-${type}-${id}.mp3`
        this.audio = new Audio([{ audioUrl }])
        this.audio.play()
      },
      cancelHandle() {
        this.maskShow = false
        this.$router.push('/courses')
      },
      finishHandle() {
        if (this.isMember) {
          this.$router.push('/courses')
        } else {
          this.maskShow = true
        }
      },
      updateData() {
        updateHistoryData(this.duration, this.xp)
        if (this.starNum >= 3) {
          updateCoursesProgressData(this.coursesLessonInfo, this.starNum)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .lessonFinish {
    position: relative;
    width: 100vw;
    height: 100vh;
    .contentWrap {
      height: calc(100vh - 140px);
      padding: 40px 0;
      box-sizing: border-box;
      overflow: auto;
      .item {
        width: 40%;
        text-align: center;
        .value {
          margin-right: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: system-ui;
          font-weight: bold;
          font-size: 26px;
          color: #000000;
          img {
            margin-right: 5px;
            width: 20px;
          }
        }
        .key {
          margin-top: 5px;
          font-family: Roboto;
          font-weight: 500;
          font-size: 12px;
          color: #000000;
        }
        &:first-of-type {
          border-right: 1px solid #9797974d;
        }
      }
      .imageWrap, .knowledgeNumWrap, .scoreWrap, .lessonVideoWrap, .masteryWrap {
        display: flex;
        justify-content: center;
        margin: 0 auto 20px;
        padding: 20px;
        width: 95%;
        max-width: 800px;
        background: #FFFFFF;
        border-radius: 12px;
        box-sizing: border-box;
      }
      .imageWrap {
        background: #004E94;
        height: 130px;
        padding: 0;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .lessonVideoWrap {
        position: relative;
        flex-direction: column;
        align-items: center;
        video {
          width: 180px;
          height: 180px;
          border: 1px solid #E4E5EC;
          border-radius: 12px;
          cursor: pointer;
        }
        .videoPlayIcon {
          position: absolute;
          top: 83.5px;
          left: calc(50% - 22.5px);
          width: 55px;
          cursor: pointer;
        }
        .videoStem {
          margin-top: 10px;
          font-family: Roboto;
          font-weight: 700;
          font-size: 18px;
          &.perfect {
            color: #3BCB94;
          }
          &.good {
            color: #F8A33B;
          }
          &.weak {
            color: #EE5151;
          }
        }
        .videoTrans {
          margin-top: 8px;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          color: #303030;
        }
        .videoIndex {
          position: absolute;
          top: 185px;
          left: calc(50% + 100px);
          opacity: 0.5;
          font-family: Roboto;
          font-weight: 400;
          font-size: 12px;
          color: #303030;
        }
      }
      .masteryWrap {
        flex-direction: column;
        padding-bottom: 40px;
        .headerWrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .headerName {
            font-family: Roboto;
            font-weight: 500;
            font-size: 18px;
            color: #000000;
          }
          .iconWrap {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .knowledgeWrap {
          margin-top: 20px;
          $perfectColor: #3BCB94;
          $perfectColorBg: #BFEDDF;
          $goodColor: #F8A33B;
          $goodColorBg: #F5E0C8;
          $weakColor: #EE5151;
          $weakColorBg: #F1CED1;
          &.perfectWrap {
            .knowledgeHeader {
              background: $perfectColorBg;
              .percent {
                background: $perfectColor;
              }
            }
            .knowledgeContent {
              border-color: $perfectColorBg;
              .knowledgeItem {
                border-color: $perfectColorBg;
                .knowledgeStem {
                  color: $perfectColor;
                }
              }
            }
          }
          &.goodWrap {
            .knowledgeHeader {
              background: $goodColorBg;
              .percent {
                background: $goodColor;
              }
            }
            .knowledgeContent {
              border-color: $goodColorBg;
              .knowledgeItem {
                border-color: $goodColorBg;
                .knowledgeStem {
                  color: $goodColor;
                }
              }
            }
          }
          &.weakWrap {
            .knowledgeHeader {
              background: $weakColorBg;
              .percent {
                background: $weakColor;
              }
            }
            .knowledgeContent {
              border-color: $weakColorBg;
              .knowledgeItem {
                border-color: $weakColorBg;
                .knowledgeStem {
                  color: $weakColor;
                }
              }
            }
          }
          &.active {
            .knowledgeHeader {
              .corner {
                transform: rotate(180deg);
              }
            }
            .knowledgeContent {
              display: block;
            }
          }
          .knowledgeHeader {
            position: relative;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            border-radius: 8px 8px 0 0;
            overflow: hidden;
            cursor: pointer;
            .knowledgeHeaderWrap {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              padding: 0 10px;
              padding-right: 46px;
              z-index: 2;
              .headerText {
                font-family: Roboto;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
              }
              .knowledgeNum {
                font-size: 20px;
                font-weight: bold;
              }
              .corner {
                display: flex;
                position: absolute;
                right: 10px;
              }
            }
            .percent {
              position: absolute;
              height: 100%;
              z-index: 1;
            }
          }
          .knowledgeContent {
            display: none;
            border-left: 1px solid;
            border-right: 1px solid;
            .knowledgeItem {
              padding: 12px;
              width: 100%;
              text-align: center;
              box-sizing: border-box;
              border-bottom: 1px solid;
              cursor: pointer;
              .knowledgeStem {
                font-weight: 600;
                font-size: 18px;
              }
              .knowledgeTrans {
                margin-top: 4px;
                font-weight: 500;
                font-size: 14px;
                color: #4F5072;
              }
            }
          }
        }
      }
    }
    .btnWrap {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 140px;
      background: #FFFFFF;
      .btnWrapContent {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .finishBtn, .nextBtn {
          padding: 0 20px;
          min-width: 190px;
          height: 42px;
          line-height: 42px;
          border-radius: 42px;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s;
        }
        // .finishBtn {
        //   margin-right: 60px;
        //   border: 2px solid #004E94;
        //   color: #004E94;
        //   &:hover {
        //     border: 2px solid #0A4172;
        //     color: #0A4172;
        //   }
        // }
        .finishBtn {
          border: 2px solid #004E94;
          background: #004E94;
          color: #FFFFFF;
          &:hover {
            border: 2px solid #0A4172;
            background: #0A4172;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .masteryExplain {
    font-weight: 500;
    font-size: 14px;
    color: #1E215A;
    .header {
      padding: 0 12px 12px;
      border-bottom: 1px solid #9797974d;
    }
    .content {
      line-height: 20px;
      padding: 12px 12px 0;
    }
  }
</style>