import request from '../utils/request'
import constants from '../utils/constants'
import EncryptDataUtil from '../utils/encryptDataUtil.js'

export async function getUnitsData(learnLang) {
  let unitsDataUrl = constants.courseUnitsSourceUrl.replace('LEARNLANG', learnLang)
  let res = await request({
    url: unitsDataUrl,
    method: 'GET',
  })
  let unitsData = EncryptDataUtil.decryptData(res.data)
  unitsData = JSON.parse(unitsData)
  
  return unitsData
}

export async function getLessonData(userLang, learnLang, lessonID) {
  if (userLang === 'cn') userLang = 'tch'
  let lessonDataUrl = constants.courseLessonSourceUrl.replace('USERLANG', userLang).replace('LEARNLANG', learnLang).replace('LESSONID', lessonID)
  let res = await request({
    url: lessonDataUrl,
    method: 'GET',
  })
  let lessonData = EncryptDataUtil.decryptData(res.data)
  lessonData = JSON.parse(lessonData)
  
  return lessonData
}

export async function getGrammarTipsData(userLang, learnLang, id) {
  if (userLang === 'cn') userLang = 'tch'
  let grammarTipsDataUrl = constants.courseGrammarSourceUrl.replace('USERLANG', userLang).replace('LEARNLANG', learnLang).replace('GRAMMARID', id)
  let res = await request({
    url: grammarTipsDataUrl,
    method: 'GET',
  })
  let grammarTipsData = res.data

  return grammarTipsData
}