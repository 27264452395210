<template>
  <div class="lessonModel W10" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="sentenceWrap">
          <div class="audio" :class="{active: audioPlaying && !['correct', 'wrong'].includes(checkStatus)}"></div>
          <div class="trans">{{trans}}</div>
        </div>
        <div class="stems">
          <template v-for="(stem, i) in stems">
            <div class="stemWrap" :key="i">
              <div class="stem" :class="{ blank: stem.char === '_' }" @click.stop="stemChooseHandle(stem)">
                {{stem.char}}
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option word" :class="{ active: op.active }" @click.stop="optionChooseHandle(op, i)" :key="i">
            <div class="optionName">{{op.char}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">
        <template v-for="(char, i) in chars">
          <div class="char" :class="{ wrong: char.wrong }" :key="i">{{char.char}}</div>
        </template>
      </div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        answerStr: '',
        stems: [],
        chars: [],
        options: []
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    watch: {
      stems: {
        handler(val) {
          if (!val.some(item => item.char === '_')) {
            this.checkStatus = 'checkReady'
          } else {
            this.checkStatus = 'default'
          }
        },
        deep: true
      }
    },
    methods: {
      init() {
        let { id, tr, ops, an, wd } = this.lessonData

        let options = wd.split('')
        if (options.length <= 6) {
          let addCharNum = 2
          for (let op of ops) {
            if (op.id !== an) {
              for (let char of op.wd) {
                if (addCharNum > 0) {
                  if (options.includes(char)) {
                    continue
                  } else {
                    options.push(char)
                    addCharNum --
                  }
                } else {
                  break
                }
              }
            }
          }
        }

        this.id = id
        this.trans = tr
        this.answerStr = wd
        this.chars = wd.split('').map(char => {
          return { char }
        })
        this.stems = wd.split('').map(() => {
          return { char: '_'}
        })
        this.options = options.sort(() => Math.random() - 0.5).map(item => {
          return { char: item }
        })
      },
      optionChooseHandle(op, index) {
        if (!op.active) {
          op.active = true

          for (let stem of this.stems) {
            if (stem.char === '_') {
              stem.char = op.char
              stem.optionIndex = index
              return
            }
          }
        }
      },
      stemChooseHandle(stem) {
        if (stem.char !== '_') {
          stem.char = '_' 
          this.options[stem.optionIndex].active = false
        }
      },
      playHandle() {
        this.$refs.checkWrap.stopVideo()
        this.playAudio('w', this.id)
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          let stemsStr = ''
          this.stems.forEach(stem => stemsStr += stem.char)

          for (let i = 0; i < this.chars.length; i ++) {
            let char = this.chars[i]
            let stemsChar = stemsStr[i]
            if (char.char !== stemsChar) {
              char.wrong = true
            }
          }

          if (stemsStr === this.answerStr) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .W10 {
    .sentenceWrap {
      display: flex;
      align-items: center;
    }
    .questionWrap {
      margin-bottom: 80px;
      .stems {
        margin-top: 40px;
        transition: all 0.3s;
        .stemWrap {
          border-bottom: 2px solid #C4C9D8;
          margin: 0 3px;
          padding: 5px;
          .stem {
            min-width: 60px;
            min-height: 60px;
          }
          .stem:not(.blank) {
            font-size: 26px;
            font-weight: 600;
            color: #000000;
            box-shadow: 0 2px 14px 0 #00000005;
            border-radius: 12px;
            background: #FFFFFF;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: #DCE4F2;
            }
          }  
        }
      }
    }
  }
</style>