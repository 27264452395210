<template>
  <div class="lessonModel S3" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="trans">{{trans}}</div>
        <div class="stems">
          <div class="audio" :class="{active: audioPlaying && !['correct', 'wrong'].includes(checkStatus)}"></div>
          <template v-for="(stem, i) in stems">
            <div class="stemWrap" :key="i">
              <div class="stem" :class="{ underline: stem.underline, blank: stem.wd === underline }">
                {{stem.wd}}
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option sentence" :class="[{active: op.id === currentOptionID}, checkStatus]" @click.stop="optionChooseHandle(op)" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">
        <template v-for="(word, i) in words">
          <div class="word" :class="{ underline: word.underline, wrong: word.wrong }" :key="i">
            {{word.wd}}
          </div>
        </template>
      </div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        stems: [],
        words: [],
        options: [],
        answerID: '',
        currentOptionID: ''
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    methods: {
      init() {
        let { id, tr, stems, wls, ops, an } = this.lessonData

        for (let i = 0; i < stems.length; i ++) {
          let stem = stems[i]
          let lastStem = stems[i - 1]

          if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastStem.wd)) {
            stem.wd = this.capitalize(stem.wd)
          }

          if (stem.wd === this.underline) {
            stem.underline = true
          }
        }

        this.id = id
        this.trans = tr
        this.stems = stems
        this.words = wls
        this.options = ops.sort(() => Math.random() - 0.5)
        this.answerID = an.replace(';', '')
      },
      optionChooseHandle(op) {
        this.currentOptionID = op.id
        this.checkStatus = 'checkReady'

        for (let i = 0; i < this.stems.length; i ++) {
          let stem = this.stems[i]
          let lastStem = this.stems[i - 1]
          let optionWord = op.wd

          if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastStem.wd)) {
            optionWord = this.capitalize(optionWord)
          }

          if (stem.underline) {
            stem.wd = optionWord
          }
        }
      },
      playHandle() {
        this.$refs.checkWrap.stopVideo()
        this.playAudio('s', this.id)
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          for (let i = 0; i < this.words.length; i ++) {
            let stem = this.stems[i]
            let word = this.words[i]
            let lastWord = this.words[i - 1]
            if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastWord.wd)) {
              word.wd = this.capitalize(word.wd)
            }
            if (stem.underline) {
              word.underline = true
              if (stem.wd.toLowerCase() !== word.wd.toLowerCase()) {
                word.wrong = true
              }
            }
          }
  
          if (this.currentOptionID === this.answerID) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S3 {
    .lessonWrap {
      .questionWrap {
        margin-bottom: 50px;
        .trans {
          margin-bottom: 12px;
          font-size: 18px;
          font-weight: 400;
          color: #9090A2;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .S3 {
      .lessonWrap {
        .questionWrap {
          margin-bottom: 20px;
        }
      }
    }
  }
</style>