<template>
  <div class="closeConfirm">
    <div class="mask" v-show="maskShow" @click="$emit('cancel')">
      <div class="closeConfirmWrap" @click.stop>
        <img @click="$emit('cancel')" class="closeImg" src="@/assets/images/clips/lesson/close.png" alt="close">
        <img class="waitImg" src="@/assets/images/courses/wait.png" alt="wait">
        <div class="waitText1">{{$t('course.lesson_quit_title')}}</div>
        <div class="waitText2">{{$t('course.lesson_quit_message')}}</div>
        <div class="btnWrap">
          <div class="closeConfirmBtn" @click="$router.push('/courses')">{{$t('course.lesson_quit_confirm')}}</div>
          <div class="closeCancelBtn" @click="$emit('cancel')">{{$t('course.lesson_quit_cancel')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['maskShow']
  }
</script>

<style lang="scss" scoped>
  .closeConfirm {
    .mask {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: #00000038;
      .closeConfirmWrap {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        width: 580px;
        border-radius: 10px;
        background: #FFFFFF;
        box-sizing: border-box;
        .closeImg {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 20px;
          cursor: pointer;
        }
        .waitImg {
          margin-top: 12px;
          width: 89px;
        }
        .waitText1 {
          margin-top: 14px;
          font-weight: 700;
          font-size: 20px;
          color: #272622;
        }
        .waitText2 {
          margin-top: 10px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #5E5E5E;
        }
        .btnWrap {
          margin-top: 30px;
          margin-bottom: 14px;
          display: flex;
          .closeConfirmBtn, .closeCancelBtn {
            min-width: 180px;
            height: 36px;
            line-height: 36px;
            border-radius: 36px;
            border: 2px solid #004E94;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s;
          }
          .closeConfirmBtn {
            margin-right: 50px;
            color: #004E94;
            &:hover {
              color: #0A4172;
              border: 2px solid #0A4172;
            }
          }
          .closeCancelBtn {
            background: #004E94;
            color: #FFFFFF;
            &:hover {
              background: #0A4172;
              border: 2px solid #0A4172;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .closeConfirm {
      .mask {
        .closeConfirmWrap {
          width: 90vw;
          .btnWrap {
            flex-direction: column;
            width: 100%;
            .closeConfirmBtn {
              margin-right: 0;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
</style>