import ResLoader from "@/plugins/resLoader"

export default {
    preloadSource: _preloadSource,
    asyncPreloadSource: _asyncPreloadSource
}

function _preloadSource(resourcesArray, callback) {
    let timeOutHandle = null;
    let isFinish = false;

    return new Promise((resolve, reject)=>{
        let loader = new ResLoader({
            resources: resourcesArray,
            onStart: function () {
                timeOutHandle = setTimeout(function () {
                    if (!isFinish) {
                        loader.stop();
                    }
                    clearTimeout(timeOutHandle);
                    timeOutHandle = null;
                }, 8000);
            },
            onProgress: function (current, total) {
            },
            onComplete: function (total) {
                isFinish = true;
                resolve(total);
            }
        });
        loader.start();
    });
}

async function _asyncPreloadSource(resourcesArray) {
    return new Promise(((resolve, reject) => {
        let loader = new ResLoader({
            resources: resourcesArray,
            onStart: function () {
            },
            onProgress: function (current, total) {
            },
            onComplete: function () {
                console.log("all resources load finished...");

                resolve();
            }
        });
        loader.start();
    }))
}

