<template>
  <div class="lessonModel S0" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="sentence">
          <div class="words">
            <template v-for="(word, i) in words">
              <el-popover
                popper-class="wordTransPopper"
                placement="bottom"
                trigger="manual"
                v-model="wordTransVisible[i]"
                :key="i">
                <div class="wordTrans">{{word.tr}}</div>
                <div v-if="word.ty === '1'" class="punc" slot="reference">{{word.wd}}</div>
                <div v-else class="word" :class="{active: i === activeIndex}" @click.stop="showWordTransHandle(word, i)" slot="reference">{{word.wd}}</div>
              </el-popover>
            </template>
          </div>
        </div>
        <div class="trans">{{trans}}</div>
      </div>

      <div class="optionsWrap">
        <div class="audioPlayerWrap">
          <div class="arrowBtn left" @click.stop="arrowBtnHandle('left')"><svg t="1681805456333" class="icon" viewBox="0 0 1756 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2724" id="mx_n_1681805456334" width="32" height="32"><path d="M1220.672 487.107657c-0.215771-0.374857-0.453486-0.731429-0.676571-1.098971-6.266514-11.536457-16.010971-21.813029-28.692114-29.134629l-462.6944-267.136c-11.918629-6.880914-24.797257-10.203429-37.198629-10.296686-0.239543-0.003657-0.475429-0.020114-0.7168-0.020114-0.142629 0-0.2816 0.010971-0.424229 0.0128-0.778971 0.007314-1.552457 0.034743-2.327771 0.065829-0.164571 0.007314-0.329143 0.009143-0.493714 0.018286-20.598857 1.000229-39.416686 11.029943-49.634743 28.728686-4.885943 8.462629-7.259429 17.751771-7.389257 27.1488-0.751543 4.337371-1.155657 8.815543-1.155657 13.4016l0 534.273829c0 4.586057 0.404114 9.064229 1.155657 13.4016 0.129829 9.3952 2.505143 18.686171 7.389257 27.1488 10.218057 17.696914 29.034057 27.726629 49.632914 28.728686 0.1664 0.009143 0.334629 0.010971 0.501029 0.018286 0.771657 0.032914 1.543314 0.060343 2.320457 0.065829 0.142629 0.001829 0.2816 0.0128 0.424229 0.0128 0.241371 0 0.479086-0.018286 0.718629-0.020114 12.401371-0.095086 25.278171-3.4176 37.1968-10.296686l462.6944-267.136c12.682971-7.3216 22.4256-17.598171 28.692114-29.134629 0.223086-0.367543 0.4608-0.724114 0.676571-1.098971 5.176686-8.965486 7.533714-18.859886 7.389257-28.8256C1228.205714 505.967543 1225.848686 496.073143 1220.672 487.107657z" fill="#ffffff" p-id="2725"></path></svg></div>
          <div class="audioPlayer" :class="{active: audioPlaying}"></div>
          <div class="arrowBtn right" @click.stop="arrowBtnHandle('right')"><svg t="1681805456333" class="icon" viewBox="0 0 1756 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2724" id="mx_n_1681805456334" width="32" height="32"><path d="M1220.672 487.107657c-0.215771-0.374857-0.453486-0.731429-0.676571-1.098971-6.266514-11.536457-16.010971-21.813029-28.692114-29.134629l-462.6944-267.136c-11.918629-6.880914-24.797257-10.203429-37.198629-10.296686-0.239543-0.003657-0.475429-0.020114-0.7168-0.020114-0.142629 0-0.2816 0.010971-0.424229 0.0128-0.778971 0.007314-1.552457 0.034743-2.327771 0.065829-0.164571 0.007314-0.329143 0.009143-0.493714 0.018286-20.598857 1.000229-39.416686 11.029943-49.634743 28.728686-4.885943 8.462629-7.259429 17.751771-7.389257 27.1488-0.751543 4.337371-1.155657 8.815543-1.155657 13.4016l0 534.273829c0 4.586057 0.404114 9.064229 1.155657 13.4016 0.129829 9.3952 2.505143 18.686171 7.389257 27.1488 10.218057 17.696914 29.034057 27.726629 49.632914 28.728686 0.1664 0.009143 0.334629 0.010971 0.501029 0.018286 0.771657 0.032914 1.543314 0.060343 2.320457 0.065829 0.142629 0.001829 0.2816 0.0128 0.424229 0.0128 0.241371 0 0.479086-0.018286 0.718629-0.020114 12.401371-0.095086 25.278171-3.4176 37.1968-10.296686l462.6944-267.136c12.682971-7.3216 22.4256-17.598171 28.692114-29.134629 0.223086-0.367543 0.4608-0.724114 0.676571-1.098971 5.176686-8.965486 7.533714-18.859886 7.389257-28.8256C1228.205714 505.967543 1225.848686 496.073143 1220.672 487.107657z" fill="#ffffff" p-id="2725"></path></svg></div>
        </div>
        <div class="recordWrap">
          <div class="recordBtn" :class="{active: recordActive, loading: recordLoading}" @click.stop="audioRecordHandle">
            <div class="load"></div>
            <svg t="1681807791161" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4310" id="mx_n_1681807791162" width="26" height="26"><path d="M512 653.553c126.779 0 229.553-102.773 229.553-229.553V229.553C741.553 102.774 638.779 0 512 0S282.447 102.774 282.447 229.553V424c0 126.779 102.774 229.553 229.553 229.553z" p-id="4311" fill="#ffffff"></path><path d="M862 424v-50h-80.418v50c0 148.886-120.696 269.582-269.582 269.582S242.418 572.886 242.418 424v-50H162v50c0 179.702 135.429 327.771 309.791 347.715v171.867H212V1024h600v-80.418H552.209V771.715C726.571 751.771 862 603.702 862 424z" p-id="4312" fill="#ffffff"></path></svg>
          </div>
          <div class="playBtn" :class="{active: playActive, loading: playLoading}" @click.stop="audioPlayHandle">
            <div class="load"></div>
            <svg t="1683271887690" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15099" width="32" height="32"><path d="M113.757091 522.984727a398.242909 398.242909 0 0 1 796.485818 0v284.439273c0 31.418182-25.506909 56.925091-56.925091 56.925091h-56.878545a56.878545 56.878545 0 0 1-56.878546-56.925091V579.956364c0-31.418182 25.460364-56.925091 56.878546-56.925091h56.878545a341.317818 341.317818 0 0 0-682.635636 0h56.878545c31.418182 0 56.878545 25.506909 56.878546 56.925091v227.514181c0 31.418182-25.460364 56.925091-56.878546 56.925091H170.682182a56.878545 56.878545 0 0 1-56.925091-56.925091v-284.392727z" fill="#ffffff" p-id="15100"></path></svg>
          </div>
        </div>
      </div>
    </div>

    <div class="checkWrap" :class="checkStatus">
      <div class="checkContent">
        <div class="leftWrap">
          <div class="iconWrap">
            <img class="checkIcon" src="@/assets/images/courses/lesson/check/default.png">
          </div>
        </div>
        <div class="rightWrap">
          <div class="checkBtn" @click.stop="checkHandle">{{$t('course.test_next')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'
  import Recorder from '@/utils/recorderUtil'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        words: [],
        activeIndex: -1,
        wordTransVisible: {},
        recorder: null,
        recordActive: false,
        playActive: false,
        recordLoading: false,
        playLoading: false
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    watch: {
      'audio.playState': function(val) {
        if (val === 'end') {
          this.checkStatus = 'checkReady'
          this.recordActive = true
        }
      }
    },
    methods: {
      init() {
        let { id, tr, wls } = this.lessonData

        for (let i = 0; i < wls.length; i ++) {
          let word = wls[i]
          let lastWord = wls[i - 1]
          this.wordTransVisible[i] = false

          if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastWord.wd)) {
            word.wd = this.capitalize(word.wd)
          }
        }

        this.id = id
        this.words = wls
        this.trans = tr
        this.recorder = Recorder
      },
      arrowBtnHandle(type) {
        let currentIndex
        if (this.activeIndex === -1) {
          currentIndex = 0
        } else {
          currentIndex = this.activeIndex
          if (type === 'left') {
            currentIndex --
            if (currentIndex < 0) {
              currentIndex = this.words.length - 1
            }
          } else if (type === 'right') {
            currentIndex ++
            if (currentIndex >= this.words.length - 1) {
              currentIndex = 0
            }
          }
        }

        let word = this.words[currentIndex]
        if (word.ty === '1') {
          this.activeIndex = currentIndex
          this.arrowBtnHandle(type)
        } else {
          this.showWordTransHandle(word, currentIndex)
        }
      },
      showWordTransHandle(word, index) {
        this.clearPopover()
        this.activeIndex = index
        this.wordTransVisible[index] = true
        this.playAudio('w', word.id)
      },
      clearPopover() {
        this.activeIndex = -1
        for (let i in this.wordTransVisible) {
          this.wordTransVisible[i] = false
        }
      },
      audioRecordHandle() {
        if (this.recorder.state === 'stop') {
          this.recorder.start(this)
          this.playActive = false
          this.recordLoading = true
        } else if (this.recorder.state === 'start') {
          this.recorder.stop()
          if (this.recorder.fileSize > 0) {
            this.playActive = true
            this.recordLoading = false
            this.audioPlayHandle()
          }
        }
      },
      audioPlayHandle() {
        if (this.playActive) {
          this.recorder.play()
          this.playLoading = true
          let unwatch = this.$watch('recorder.playState', function(val) {
            if (val === 'playend') {
              this.recorder.stopPlay()
              this.playLoading = false
              unwatch()
            }
          })
        }
      },
      playHandle() {
        this.clearPopover()
        this.playAudio('s', this.id)
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          this.nextHandle()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S0 {
    .lessonWrap {
      .questionWrap {
        .sentence {
          font-weight: 500;
          font-size: 22px;
          color: #2F2F4D;
          .words {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .word {
              padding: 3px;
              transition: all 0.3s;
              cursor: pointer;
              &:hover {
                background: #E1ECFF;
                color: #004E94;
              }
              &.active {
                background: #E1ECFF;
                color: #004E94;
              }
            }
          }
        }
        .trans {
          margin-top: 12px;
          font-weight: 400;
          font-size: 18px;
          color: #9090A2;
        }
      }
      .optionsWrap {
        display: block;
        .audioPlayerWrap {
          display: flex;
          align-items: center;
          .audioPlayer {
            margin: -10px 12px 0;
          }
          .arrowBtn {
            width: 30px;
            height: 48px;
            background: #7A9AB8;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px 14px 14px 4px;
            cursor: pointer;
            &.left {
              transform: rotate(180deg);
            }
          }
        }
        .recordWrap {
          margin-top: 80px;
          display: flex;
          justify-content: center;
          .recordBtn, .playBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            background: #CBD8EE;
            .load {
              display: none;
              position: relative;
              width: 3px;
              height: 5px;
              background: #FFFFFF;
              animation: rectangle infinite 1s ease-in-out -0.2s;
              &:before, &:after {
                position: absolute;
                width: 3px;
                height: 5px;
                content: "";
                background-color: #FFFFFF;
              }
              &:before {
                left: -7px;
                animation: rectangle infinite 1s ease-in-out -0.4s;
              }
              &:after {
                right: -7px;
                animation: rectangle infinite 1s ease-in-out;
              }
              @keyframes rectangle {
                0%, 80%, 100% {
                  height: 10px;
                  box-shadow: 0 0 #FFFFFF;
                }
                40% {
                  height: 15px;
                  box-shadow: 0 -10px #FFFFFF;
                  transform: translateY(2.5px);
                }
              }
            }
            &.active {
              background: #004E94;
              cursor: pointer;
              transition: all 0.3s;
              &:hover {
                background: #0A4172;
              }
            }
            &.loading {
              .load {
                display: block;
              }
              svg {
                display: none;
              }
            }
          }
          .recordBtn {
            margin-right: 140px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .S0 {
      .lessonWrap {
        .questionWrap {
          margin-bottom: 50px;
        }
        .optionsWrap {
          .audioPlayerWrap {
            justify-content: space-between;
          }
          .recordWrap {
            margin-top: 50px;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .wordTransPopper {
    padding: 20px;
    font-size: 20px;
    text-align: center;
  }
</style>