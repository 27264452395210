const learnTimeMixins = {
  data() {
    return {
      duration: 0,
    }
  },
  created() {
    document.addEventListener('visibilitychange', this.visibilitychange)
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilitychange)
  },
  methods: {
    timingStart() {
      this.startTime = Date.parse(new Date())
    },
    timingStop() {
      let endTime = Date.parse(new Date())
      this.duration += (endTime - this.startTime) / 1000
    },
    visibilitychange() {
      if (document.visibilityState === 'hidden') {
        this.timingStop()
      } else if (document.visibilityState === 'visible') {
        this.startTime = Date.parse(new Date())
      }
    }
  }
}

export default learnTimeMixins