<template>
  <div class="coursesLesson">
    <template v-if="finish">
      <finish :coursesLessonInfo="coursesLessonInfo" :lessonsFinishData="lessonsFinishData" :duration="duration" class="animate__animated animate__fadeIn"></finish>
    </template>

    <template v-else-if="testoutFailed">
      <testout-failed class="animate__animated animate__fadeIn"></testout-failed>
    </template>

    <template v-else>
      <div class="lessonHeader">
        <div class="close" @click="closeClickHandle">
          <img src="@/assets/images/clips/lesson/close.png" alt="close">
        </div>

        <div class="progress">
          <div class="progressBar" :style="{width: progressVal}"></div>
        </div>

        <div v-if="lessonType === 'normal'" class="grammarTips" @click="grammarTipsClickHandle">
          <img src="@/assets/images/courses/book.png" alt="grammarTips">
        </div>

        <div v-if="lessonType === 'testout'" class="testoutIconWrap">
          <template v-for="i in 4">
            <img v-if="i > 4 - wrongNumber" :key="i" src="@/assets/images/courses/lesson/testoutIconGrey.png" alt="testoutGreyIcon">
            <img v-else :key="i" src="@/assets/images/courses/lesson/testoutIcon.png" alt="testoutIcon">
          </template>
        </div>

        <el-popover
          placement="bottom-end"
          width="240"
          trigger="click">
          <div class="settingsWrap">
            <div class="settingItem">
              <div class="settingName">{{$t('course.sound_effect')}}</div>
              <el-switch
                v-model="soundEffect"
                active-color="#DCE4F2">
              </el-switch>
            </div>
            <div class="settingItem">
              <div class="settingName">{{$t('course.sound_speed')}}</div>
              <div class="playBackRateWrap">
                <div class="playBackRateVal">{{(playBackRate * 100).toFixed() + '%'}}</div>
                <el-slider
                  v-model="playBackRate"
                  :min="0.5"
                  :max="1.5"
                  :step="0.1"
                  :show-tooltip="false">
                </el-slider>
              </div>
            </div>
          </div>
          <div class="settings" slot="reference">
            <img src="@/assets/images/courses/setting.png" alt="settings">
          </div>
        </el-popover>
      </div>

      <div v-if="testoutExplain" class="testoutExplain">
        <div class="testoutExplainWrap">
          <img src="@/assets/images/courses/lesson/testoutExplain.png" alt="testoutExplainIcon">
          <div class="testoutExplainText">{{$t('course.test_out_intro_info')}}</div>
        </div>
        <div class="continueBtn" @click="testoutExplainSkip">{{$t('course.continue_txt')}}</div>
      </div>

      <div v-else class="lessonMain">
        <component
          ref="lessonModel"
          :is="currentModel"
          :lessonData="currentLesson"
          :lessonIndex="lessonIndex"
          :seriesCorrectNumber="seriesCorrectNumber"
          @correct="correctHandle"
          @wrong="wrongHandle"
          @goNext="lessonIndex++"
          :key="lessonIndex"
        />
      </div>

      <close-confirm :maskShow="maskShow" @cancel="maskShow=false"></close-confirm>

      <el-drawer
        title="grammarTips"
        :with-header="false"
        :visible.sync="grammarTipsDrawer"
        size="90%"
        direction="btt">
        <div class="grammarTipsDrawer" v-loading="grammarTipsLoading">
          <div class="grammarTipsWrap">
            <div class="content" v-html="grammarTips"></div>
          </div>
        </div>
      </el-drawer>
    </template>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { getLessonData, getGrammarTipsData } from '@/api/courses'
  import constants from '@/utils/constants'
  import preLoadUtil from '@/utils/preLoadUtil'
  import phraseModel from '@/components/courses/models/phraseModel.vue'
  import wordM1 from '@/components/courses/models/wordM1.vue'
  import wordM3 from '@/components/courses/models/wordM3.vue'
  import wordM5 from '@/components/courses/models/wordM5.vue'
  import wordM10 from '@/components/courses/models/wordM10.vue'
  import sentenceM0 from '@/components/courses/models/sentenceM0.vue'
  import sentenceM1 from '@/components/courses/models/sentenceM1.vue'
  import sentenceM2 from '@/components/courses/models/sentenceM2.vue'
  import sentenceM3 from '@/components/courses/models/sentenceM3.vue'
  import sentenceM4 from '@/components/courses/models/sentenceM4.vue'
  import sentenceM5 from '@/components/courses/models/sentenceM5.vue'
  import sentenceM8 from '@/components/courses/models/sentenceM8.vue'
  import sentenceM9 from '@/components/courses/models/sentenceM9.vue'
  import sentenceM10 from '@/components/courses/models/sentenceM10.vue'
  import sentenceM13 from '@/components/courses/models/sentenceM13.vue'
  import finish from '@/components/courses/LessonFinish.vue'
  import closeConfirm from '@/components/CloseConfirmWindow.vue'
  import testoutFailed from '@/components/courses/TestoutFailed.vue'
  import learnTimeMixins from '@/mixins/learnTimeMixins'

  // 忽略的题型：语音题暂时不做
  const SkipModels = ['7']

  export default {
    mixins: [ learnTimeMixins ],
    data() {
      return {
        id: '',
        lessonType: '',
        lessonsData: [],
        lessonIndex: 0,
        currentModel: '',
        currentLesson: {},
        seriesCorrectNumber: 0,
        wrongNumber: 0,
        maskShow: false,
        finish: false,
        testoutFailed: false,
        testoutExplain: false,
        grammarTipsLoading: false,
        grammarTipsDrawer: false,
        grammarTips: '',
        lessonsFinishData: []
      }
    },
    components: {
      wordM1,
      wordM3,
      wordM5,
      wordM10,
      sentenceM0,
      sentenceM1,
      sentenceM2,
      sentenceM3,
      sentenceM4,
      sentenceM5,
      sentenceM8,
      sentenceM9,
      sentenceM10,
      sentenceM13,
      phraseModel,
      testoutFailed,
      finish,
      closeConfirm,
    },
    computed: {
      ...Vuex.mapGetters(['testSettings', 'coursesLessonInfo', 'userLanguage', 'learnLanguage', 'userSettings']),
      progressVal() {
        let progess = 0
        let total = this.lessonsData.length
        if (total > 0) {
          let current = this.lessonIndex + 1
          progess = ((current / total) * 100).toFixed(2) + '%'
        }
        return progess
      },
      soundEffect: {
        get() {
          return this.userSettings.soundEffect
        },
        set(val) {
          this.$store.commit('set_userSettings', { key: 'soundEffect', val })
        }
      },
      playBackRate: {
        get() {
          return this.userSettings.playBackRate
        },
        set(val) {
          this.$store.commit('set_userSettings', { key: 'playBackRate', val })
        }
      }
    },
    watch: {
      lessonIndex(index) {
        if (this.lessonType === 'testout' && this.wrongNumber >= 4) {
          this.testoutFailed = true
        } else {
          if (index < this.lessonsData.length) {
            let currentLesson = this.lessonsData[index]
            this.currentLesson = currentLesson
            this.currentModel = this.getModel(currentLesson)
            this.checkStatus = 'default'
          } else {
            this.finishHandle()
          }
        }
      }
    },
    mounted() {
      this.correctAudio = new Audio(constants.correctAudioUrl)
      this.wrongAudio = new Audio(constants.wrongAudioUrl)
    },
    created() {
      this.initLessons()
    },
    methods: {
      async initLessons() {
        if (this.coursesLessonInfo) {
          let loading = this.$loading()
          this.id = this.coursesLessonInfo.id
          this.lessonType = this.coursesLessonInfo.type

          let lessonData = await getLessonData(this.userLanguage, this.learnLanguage, this.id)
          this.lessonsData = this.handleLessonsData(lessonData)

          this.$nextTick(async function() {
            await this.preloadAssets()
            if (this.lessonType === 'testout') {
              this.testoutExplain = true
              setTimeout(() => {
                this.testoutExplain = false
              }, 2000)
            }

            this.currentLesson = this.lessonsData[this.lessonIndex]
            this.currentModel = this.getModel(this.currentLesson)
            this.timingStart()
            loading.close()
          })
        } else {
          this.$router.push('/courses')
        }
      },
      async preloadAssets() {
        await this.preloadVideos()
      },
      async preloadVideos() {
        let videoList = []
        for (let lesson of this.lessonsData) {
          let { id, kty, kmo } = lesson
          // 跳过配对题和S0
          if (kty === '3' || (kty === '1' && kmo === '0')) {
            continue
          }
          let type = kty === '1' ? 's' : 'w'
          let videoURL = constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
            'lesson_f/' +
            constants.langsData[this.learnLanguage]['courseMediaName'] +
            `-f-${type}-${id}.mp4`
          videoList.push(videoURL)
        }
        await preLoadUtil.asyncPreloadSource(videoList)
      },
      handleLessonsData(lessonData) {
        let lessonsData = []
        let { lastRegex, list } = lessonData
        let regexArr = lastRegex.split('#')
        for (let regex of regexArr) {
          let regexStr = regex.split(';')[0]
          let regexNum = Number(regex.split(';')[2])

          // 处理配对题
          if (regexStr.charAt(0) === '3') {
            let regexSpecial = regexStr.slice(0, regexStr.length - 1).replace(/:-/g, '-')
            let lessonObj = list[regexSpecial]
            lessonsData.push(lessonObj)
            continue
          }

          // 处理一道题出多个题型的情况
          if (regexStr.indexOf(',') > 0) {
            let regexArr = regexStr.split('-')
            let regexsArr = []
            for (let regex of regexArr) {
              let kty = regex.split(':')[0]
              let id = regex.split(':')[1]
              let kmoStr = regex.split(':')[2]
              let kmosArr = kmoStr.split(',')
              for (let kmo of kmosArr) {
                let regex = `${kty}:${id}:${kmo}`
                regexsArr.push(regex)
              }
            }
            regexStr = regexsArr.join('-')
          }
          
          // 处理多个题中出几道的情况
          let regexsArr = regexStr.split('-')
          if (regexsArr.length === regexNum) {
            for (let regex of regexsArr) {
              let lessonObj = list[regex]
              lessonsData.push(lessonObj)
            }
          } else if (regexsArr.length > regexNum) {
            for (let i = 0; i < regexNum; i ++) {
              let index = Math.floor(Math.random() * regexsArr.length)
              let regex = regexsArr[index]
              let lessonObj = list[regex]
              lessonsData.push(lessonObj)
              regexsArr.splice(index, 1)
            }
          }
        }
        
        return this.deleteSkipModels(lessonsData)
      },
      getModel(lesson) {
        let type = ''
        let model = lesson.kmo
        if (lesson.kty === '0') {
          type = 'word'
        } else if (lesson.kty === '1') {
          type = 'sentence'
          if (model === '14') {
            model = 4
          }
        } else if (lesson.kty === '3' && !model) {
          return 'phraseModel'
        }
        return type + 'M' + model
      },
      closeClickHandle() {
        if (this.lessonIndex > 0) {
          this.maskShow = true
        } else {
          this.$router.push('/courses')
        }
      },
      async grammarTipsClickHandle() {
        this.grammarTipsDrawer = true

        if (!this.grammarTips) {
          this.grammarTipsLoading = true
          let unitID = this.coursesLessonInfo.unitID
          let grammarTipsData = await getGrammarTipsData(this.userLanguage, this.learnLanguage, unitID)
          let grammarTips = grammarTipsData.replace(/\#ea9500/gi, '#004E94')
          grammarTips = grammarTips.replace("<link href='https://wap.lingodeer.com/javascript/jsLibrary/richText/css/froala_style.min.css' rel='stylesheet' type='text/css'/>", '')
          this.grammarTips = grammarTips
          this.grammarTipsLoading = false
        }
      },
      correctHandle() {
        if (this.userSettings.soundEffect) this.correctAudio.play()
        this.seriesCorrectNumber ++
        this.lessonsFinishData.push({
          data: this.currentLesson,
          result: true
        })
      },
      wrongHandle() {
        if (this.userSettings.soundEffect) this.wrongAudio.play()
        this.seriesCorrectNumber = 0
        this.wrongNumber ++
        this.lessonsFinishData.push({
          data: this.currentLesson,
          result: false
        })
      },
      finishHandle() {
        this.finish = true
        this.timingStop()
      },
      testoutExplainSkip() {
        this.testoutExplain = false
      },
      // 删除忽略的题型、去重
      deleteSkipModels(lessons) {
        return lessons.filter((item, index, array) => {
          return item && !SkipModels.includes(item?.kmo) && array.indexOf(item) === index
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .coursesLesson {
    position: relative;
    background: #F3F4F8;
    .lessonHeader {
      margin: 0 auto;
      padding-top: 10px;
      height: 30px;
      width: 90vw;
      max-width: 960px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      .testoutIconWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        img {
          width: 37px;
        }
      }
      .close, .grammarTips, .settings {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
      }
      .close {
        img {
          width: 17px;
        }
      }
      .grammarTips, .settings {
        img {
          width: 22px;
        }
      }
      .progress {
        position: relative;
        margin: 0 10px;
        width: 100%;
        height: 12px;
        border-radius: 12px;
        background: #D8D8D8;
        overflow: hidden;
        &Bar {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 100%;
          height: 100%;
          border-radius: 12px;
          background: #2BC58A;
          transition: width .5s;
        }
      }
    }
    .testoutExplain {
      position: relative;
      width: 100%;
      height: calc(100vh - 30px);
      display: flex;
      justify-content: center;
      align-items: center;
      .testoutExplainWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 99px;
        }
        .testoutExplainText {
          margin-left: 40px;
          margin-bottom: 120px;
          position: relative;
          padding: 20px;
          width: 200px;
          background: #F5F8FD;
          border: 2px solid #CBD8F1;
          border-radius: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #1E215A;
          &:before, &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-bottom: 20px solid #CBD8F1;
            left: -21px;
            bottom: -2px;
          }
          &:after {
            border-bottom: 20px solid #F5F8FD;
            left: -16.5px;
            bottom: 0;
          }
        }
      }
      .continueBtn {
        position: absolute;
        bottom: 60px;
        margin-top: 100px;
        width: 300px;
        height: 46px;
        line-height: 46px;
        border-radius: 46px;
        background: #004E94;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: #0A4172;
        }
      }
    }
    .lessonMain {
      width: 100%;
      height: calc(100vh - 30px);
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      &::-webkit-scrollbar {
        width: 0;
      }
      &.dialogue {
        box-sizing: border-box;
        padding-bottom: 20vh;
      }
    }
    .grammarTipsDrawer {
      width: 100%;
      height: 100%;
      .grammarTipsWrap {
        padding: 0;
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .coursesLesson {
      .grammarTipsDrawer {
        .grammarTipsWrap {
          width: 100%;
        }
      }
    }
  }
</style>

<style lang="scss">
  .settingsWrap {
    padding: 8px;
    .settingItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 50px;
      .settingName {
        font-weight: 400;
        font-size: 14px;
        color: #2F2F4D;
      }
      .playBackRateWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .playBackRateVal {
          margin-right: 8px;
          font-weight: 400;
          font-size: 12px;
          color: #004E94;
        }
        .el-slider {
          width: 80px;
        }
        .el-slider__runway {
          background: #DCE4F2;
        }
        .el-slider__bar {
          background: #004E94;
        }
        .el-slider__button {
          width: 10px;
          height: 10px;
          background: #004E94;
          border: 1px solid #FFFFFF;
        }
      }
    }
  }

  .el-switch .el-switch__core:after {
    background: #004E94;
  }
</style>