<template>
  <div class="lessonModel S10" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="trans">{{trans}}</div>
        <div class="stems">
          <div class="audio" :class="{active: audioPlaying && !['correct', 'wrong'].includes(checkStatus)}"></div>
          <template v-for="(stem, i) in stems">
            <div class="stemWrap" :key="i">
              <div class="stem" :class="{ underline: stem.underline, blank: stem.wd === underline, highlight: stem.highlight }" @click.stop="stemChooseHandle(stem)">
                {{stem.wd}}
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option word" :class="{ active: op.active }" @click.stop="optionChooseHandle(op, i)" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">
        <template v-for="(word, i) in words">
          <div class="word" :class="{ underline: word.underline, wrong: word.wrong }" :key="i">
            {{word.wd}}
          </div>
        </template>
      </div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        stems: [],
        words: [],
        options: [],
        answerIDs: [],
        chooseIDs: []
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    watch: {
      chooseIDs(val) {
        if (val.length === this.answerIDs.length) {
          this.checkStatus = 'checkReady'
        } else {
          this.checkStatus = 'default'
        }
      },
      stems: {
        handler: function(val) {
          val.forEach(stem => stem.highlight = false)
          let stem = val.find(stem => stem.wd === this.underline)
          if (stem) stem.highlight = true
        },
        deep: true
      }
    },
    methods: {
      init() {
        let { id, tr, stems, ops, wls } = this.lessonData
        let answerIDs = []
        let underlineIndex = 0

        for (let i = 0; i < stems.length; i ++) {
          let stem = stems[i]
          let lastStem = stems[i - 1]
          if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastStem.wd)) {
            stem.wd = this.capitalize(stem.wd)
          }
          if (stem.wd === this.underline) {
            stem.underline = true
            stem.underlineIndex = underlineIndex
            underlineIndex ++
            answerIDs.push(wls[i].id)
          }
        }

        this.id = id
        this.trans = tr
        this.stems = stems
        this.words = wls
        this.options = ops.sort(() => Math.random() - 0.5)
        this.answerIDs = answerIDs
      },
      optionChooseHandle(op, index) {
        if (!op.active) {
          op.active = true

          for (let i = 0; i < this.stems.length; i ++) {
            let stem = this.stems[i]
            let lastStem = this.stems[i - 1]
            let optionWord = op.wd

            if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastStem.wd)) {
              optionWord = this.capitalize(optionWord)
            }

            if (stem.wd === this.underline) {
              stem.wd = optionWord
              stem.optionIndex = index
              this.chooseIDs.splice(stem.underlineIndex, 0, op.id)
              return
            }
          }
        }
      },
      stemChooseHandle(stem) {
        if ((stem.underline) && stem.wd !== this.underline) {
          let spliceIndex = 0
          let underlineStems = this.stems.filter(stem => stem.underline)
          for (let i = 0; i < underlineStems.length; i ++) {
            let underlineStem = underlineStems[i]
            if (underlineStem.wd !== this.underline) {
              if (stem.underlineIndex === underlineStem.underlineIndex) {
                stem.wd = this.underline
                this.chooseIDs.splice(spliceIndex, 1)
                this.options[stem.optionIndex].active = false
                return
              }
              spliceIndex ++
            }
          }
        }
      },
      playHandle() {
        this.$refs.checkWrap.stopVideo()
        this.playAudio('s', this.id)
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          for (let i = 0; i < this.words.length; i ++) {
            let stem = this.stems[i]
            let word = this.words[i]
            let lastWord = this.words[i - 1]
            if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastWord.wd)) {
              word.wd = this.capitalize(word.wd)
            }
            if (stem.underline) {
              word.underline = true
              if (stem.wd.toLowerCase() !== word.wd.toLowerCase()) {
                word.wrong = true
              }
            }
          }
  
          if (this.chooseIDs.join('') === this.answerIDs.join('')) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S10 {
    .lessonWrap {
      .questionWrap {
        .trans {
          margin-bottom: 12px;
          font-size: 18px;
          font-weight: 400;
          color: #9090A2;
        }
        .stems {
          transition: all 0.3s;
          .stemWrap {
            margin: 0 3px;
            .stem.underline {
              box-sizing: border-box;
              padding: 0 10px;
              min-width: 60px;
              min-height: 60px;
              border-bottom: 1px solid #A2A2A2;
              &.highlight {
                border-bottom: 2px solid #004E94;
              }
            }
            .stem.underline:not(.blank) {
              border-radius: 12px;
              border-bottom: 2px solid transparent;
              box-shadow: 0 2px 14px 0 #00000005;
              background: #FFFFFF;
              cursor: pointer;
              transition: all 0.3s;
              &:hover {
                background: #DCE4F2;
              }
            }  
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .S10 {
      .lessonWrap {
        .questionWrap {
          margin-bottom: 50px;
        }
      }
    }
  }
</style>