<template>
  <div class="buyWindow">
    <div class="mask" v-show="maskShow" @click="$emit('cancel')">
      <div class="contentWrap" @click.stop>
        <img @click="$emit('cancel')" class="closeImg" src="@/assets/images/clips/lesson/close.png" alt="close">
        <div class="imgWrap">
          <img src="@/assets/images/joinPremium.png" alt="joinPremium">
        </div>
        <div class="slogan">{{$t('buy.slogan2')}}</div>
        <router-link to="/pricing" class="buyBtn">
          <div class="crownIcon"></div>
          {{$t('buy.join_premium')}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['maskShow']
  }
</script>

<style lang="scss" scoped>
  .buyWindow {
    .mask {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: #00000038;
      z-index: 1000;
      .contentWrap {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 530px;
        border-radius: 10px;
        background: #FFFFFF;
        box-sizing: border-box;
        overflow: hidden;
        .closeImg {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 20px;
          cursor: pointer;
        }
        .imgWrap {
          width: 100%;
          height: 133px;
          background: #004E94;
          img {
            height: 100%;
            display: block;
            margin: 0 auto;
          }
        }
        .slogan {
          margin: 40px 0;
          font-family: Helvetica;
          font-weight: 700;
          font-size: 20px;
          color: #09123B;
        }
        .buyBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 32px;
          min-width: 300px;
          height: 46px;
          line-height: 46px;
          border-radius: 46px;
          background: #004E94;
          font-weight: 700;
          font-size: 16px;
          color: #FFFFFF;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background: #0A4172;
          }
          .crownIcon {
            margin-right: 10px;
            width: 24px;
            height: 20px;
            mask-size: cover;
            mask-image: url('~@/assets/images/courses/unit/crown.png');
            background: #FFFFFF;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .buyWindow {
      .mask {
        .contentWrap {
          width: 90vw;
          .slogan {
            margin: 40px 20px;
          }
        }
      }
    }
  }
</style>