<template>
  <div class="lessonModel phraseModel">
    <div class="lessonWrap">
      <div class="tips">{{$t('course.tap_the_pairs')}}</div>
      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option phrase" :class="op.status" @click="optionChooseHandle(op, i)" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <div class="checkWrap" :class="checkStatus">
      <div class="checkContent">
        <div class="leftWrap">
          <div class="iconWrap">
            <img class="checkIcon" src="@/assets/images/courses/lesson/check/default.png">
          </div>
        </div>
        <div class="rightWrap">
          <div class="checkBtn" @click="nextHandle">{{$t('course.test_next')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        options: [],
        currentOptionID: '',
        currentOptionIndex: ''
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        let { phs } = this.lessonData
        let options = []
        for (let phrase of phs) {
          options.push({ wd: phrase.ph, id: phrase.id })
          options.push({ wd: phrase.tr, id: phrase.id })
        }

        this.options = options.sort(() => Math.random() - 0.5)
      },
      optionChooseHandle(op, index) {
        if (!op.status) {
          if (this.currentOptionID === '') {
            this.currentOptionID = op.id
            this.currentOptionIndex = index
            this.$set(this.options[index], 'status', 'active')
          } else {
            if (this.currentOptionID === op.id) {
              this.$set(this.options[index], 'status', 'right')
              this.$set(this.options[this.currentOptionIndex], 'status', 'right')
              this.currentOptionID = ''
              this.currentOptionIndex = ''
              this.playHandle(op.id)
              this.checkHandle()
            } else {
              this.$set(this.options[index], 'status', 'wrong')
              this.$set(this.options[this.currentOptionIndex], 'status', 'wrong')
              let that = this
              setTimeout(() => {
                that.$set(that.options[index], 'status', null)
                that.$set(that.options[that.currentOptionIndex], 'status', null)
                this.currentOptionID = ''
                this.currentOptionIndex = ''
              }, 300)
            }
          }
        }
      },
      playHandle(id) {
        this.playAudio('p', id)
      },
      checkHandle() {
        if (!this.options.some(op => op.status !== 'right')) {
          this.checkStatus = 'checkReady'
        }
      },
      nextHandle() {
        if (this.checkStatus === 'checkReady') {
          this.$emit('goNext')
        }
      }
    }
  }
</script>