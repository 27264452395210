<template>
  <div class="lessonModel W1" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="trans">{{$t('course.select_')}} "{{trans}}"</div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option image" :class="[{active: op.id === currentOptionID}, checkStatus]" @click="optionChooseHandle(op)" :key="i">
            <img class="optionImg" :src="op.im" alt="option">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>
  
    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">{{word}}</div>
    </check-wrap>
  </div>
</template>

<script>
  import constants from '@/utils/constants'
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        word: '',
        trans: '',
        answerID: '',
        options: [],
        currentOptionID: ''
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        let { id, wd, tr, an, ops } = this.lessonData
        for (let op of ops) {
          let imgURL =
            constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
            'lesson_png/' +
            constants.langsData[this.learnLanguage]['courseMediaName'] +
            `-p-${op.id}-${op.im}`
          op.im = imgURL
        }
        this.id = id
        this.word = wd
        this.trans = tr
        this.answerID = an
        this.options = ops.sort(() => Math.random() - 0.5)
      },
      optionChooseHandle(op) {
        this.playWordAudio(op.id)
        this.currentOptionID = op.id
        this.checkStatus = 'checkReady'
      },
      playHandle() {
        if (['correct', 'wrong'].includes(this.checkStatus)) {
          this.$refs.checkWrap.stopVideo()
          this.playAudio('w', this.id)
        }
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          if (this.currentOptionID === this.answerID) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
          this.playHandle()
        }
      }
    }
  }
</script>