import { render, staticRenderFns } from "./wordM3.vue?vue&type=template&id=1402264e&scoped=true&"
import script from "./wordM3.vue?vue&type=script&lang=js&"
export * from "./wordM3.vue?vue&type=script&lang=js&"
import style0 from "./wordM3.vue?vue&type=style&index=0&id=1402264e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1402264e",
  null
  
)

export default component.exports