<template>
  <div class="lessonModel S8" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap" :class="{ reverse: !position }">
        <div class="dialogWrap dialogMinor">
          <div class="sentenceWrap">
            <div class="audio" :class="{active: audioPlaying && playingRole === 1 && !['correct', 'wrong'].includes(checkStatus)}"></div>
            <div class="sentence">{{minorData.st}}</div>
          </div>
          <img class="femaleIcon" src="@/assets/images/female.png" alt="female">
        </div>

        <div class="dialogWrap dialogMajor">
          <img class="maleIcon" src="@/assets/images/male.png" alt="male">
          <div class="sentenceWrap">
            <div class="stemsWrap">
              <div class="audio" :class="{active: audioPlaying && playingRole === 2 && !['correct', 'wrong'].includes(checkStatus)}"></div>
              <div class="stems">
                <template v-for="(stem, i) in stems">
                  <div class="stem" @click.stop="stemChooseHandle(stem, i)" :key="i">
                    {{stem.wd}}
                  </div>
                </template>
              </div>
            </div>
            <div class="trans">{{trans}}</div>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option word" :class="{active: op.active}" @click.stop="optionChooseHandle(op)" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">
        <template v-for="(word, i) in words">
          <div class="word" :class="{ underline: word.underline, wrong: word.wrong }" :key="i">
            {{word.wd}}
          </div>
        </template>
      </div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        position: true,
        minorData: {},
        stems: [],
        words: [],
        options: [],
        answerIDs: [],
        playingRole: 0
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    watch: {
      stems(val) {
        if (val.length) {
          this.checkStatus = 'checkReady'
        } else {
          this.checkStatus = 'default'
        }
      }
    },
    methods: {
      init() {
        let { id, tr, postion, stem, ops, wls } = this.lessonData
        let answerIDs = []
        let options = []
        for (let word of wls) {
          if (word.ty !== '1') {
            answerIDs.push(word.id)
          }
        }

        for (let word of ops) {
          if (word.ty !== '1') {
            options.push(word)
          }
        }

        this.id = id
        this.trans = tr
        this.position = postion === 'True' ? true : false
        this.minorData = stem
        this.answerIDs = answerIDs
        this.options = options.sort(() => Math.random() - 0.5)
        this.words = wls
      },
      optionChooseHandle(op) {
        if (!op.active) {
          op.active = true
          this.stems.push(op)
          this.playWordAudio(op.id)
        }
      },
      stemChooseHandle(stem, index) {
        stem.active = false
        this.stems.splice(index, 1)
      },
      playHandle() {
        if (['correct', 'wrong'].includes(this.checkStatus)) {
          this.$refs.checkWrap.stopVideo()
          this.playAudio('s', this.id)
        } else {
          let firstAudioID, secondAudioID
          if (this.position) {
            firstAudioID = this.minorData.id
            secondAudioID = this.id
          } else {
            firstAudioID = this.id
            secondAudioID = this.minorData.id
          }
  
          this.playAudio('s', firstAudioID)
          this.playingRole = this.position ? 1 : 2
          let unwatch = this.$watch('audio.playState', function(val) {
            if (val === 'end') {
              this.playAudio('s', secondAudioID)
              this.playingRole = this.position ? 2 : 1
              unwatch()
            }
          })
        }
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          let chooseIDs = []
          for (let word of this.stems) {
            chooseIDs.push(word.id)
          }

          let words = this.words.filter(item => item.ty !== '1')
          for (let i = 0; i < words.length; i ++) {
            let stem = this.stems[i]
            let word = words[i]
            if (stem) {
              if (stem.wd.toLowerCase() !== word.wd.toLowerCase()) {
                word.wrong = true
              }
            } else {
              word.wrong = true
            }
          }
          for (let i = 0; i < this.words.length; i ++) {
            let word = this.words[i]
            let lastWord = this.words[i - 1]
            if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastWord.wd)) {
              word.wd = this.capitalize(word.wd)
            }
          }
  
          if (chooseIDs.join('') === this.answerIDs.join('')) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S8 {
    .lessonWrap {
      .divider {
        margin-bottom: 34px;
      }
      .audio {
        margin-right: 16px;
      }
      .questionWrap {
        margin-bottom: 40px;
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        &.reverse {
          flex-direction: column-reverse;
        }
        .dialogWrap {
          margin: 30px 0;
          display: flex;
          align-items: flex-start;
          .maleIcon, .femaleIcon {
            margin: -10px 10px 0;
            width: 44px;
          }
          &.dialogMinor {
            align-self: flex-end;
            .sentenceWrap {
              display: flex;
              align-items: center;
              background: #FFFFFF;
              border: 2px solid #CFD6DD;
              .sentence {
                font-weight: 500;
                font-size: 20px;
                color: #2F2F4D;
              }
              &:before, &:after {
                border-right: 20px solid #CFD6DD;
                top: -12px;
                right: -2px;
              }
              &:after {
                border-right: 20px solid #FFFFFF;
                top: -8.5px;
                right: 0;
              }
            }
          }
          &.dialogMajor {
            align-self: flex-start;
            .sentenceWrap {
              background: #F5F8FD;
              border: 2px solid #CBD8F1;
              &:before, &:after {
                border-left: 20px solid #CBD8F1;
                top: -12px;
                left: -2px;
              }
              &:after {
                border-left: 20px solid #F5F8FD;
                top: -8.5px;
                left: 0;
              }
              .stemsWrap {
                display: flex;
                align-items: center;
                .stems {
                  margin-bottom: 8px;
                  border-bottom: 1px dashed #979797;
                  min-height: 48px;
                  min-width: 300px;
                  .stem {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 50px;
                    min-height: 38px;
                    box-shadow: 0 2px 14px 0 #00000005;
                    border-radius: 12px;
                    padding: 0 10px;
                    margin: 5px;
                    background: #FFFFFF;
                    font-weight: 500;
                    font-size: 20px;
                    color: #2F2F4D;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover {
                      background: #DCE4F2;
                    }
                  }
                }
              }
              .trans {
                padding-left: 45px;
                font-weight: 400;
                font-size: 16px;
                color: #585971;
              }
            }
          }
          .sentenceWrap {
            position: relative;
            font-size: 20px;
            padding: 14px 20px;
            border-radius: 12px;
            &:before, &:after {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              border: 10px solid transparent;
            }
          }
        }
      }
      .optionsWrap {
        .option {
          min-width: 50px;
          min-height: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .S8 {
      .lessonWrap {
        .questionWrap {
          .dialogWrap {
            margin: 20px 0;
            &.dialogMajor .sentenceWrap .stemsWrap .stems {
              min-width: calc(90vw - 150px);
            }
          }
        }
      }
    }
  }
</style>