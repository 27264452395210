(function (root, factory) {
    if (typeof exports === 'object' && typeof module !== 'undefined') {
        module.exports = factory();
    } else {
        root.ResLoader = factory(root);
    }
}(this, function () {
    let isFunc = function (f) {
        return typeof f === 'function';
    };

    function ResLoader(config) {
        this.option = {
            resourceType: 'image', //资源类型，默认为图片
            baseUrl: './', //基准url
            resources: [], //资源路径数组
            onStart: null, //加载开始回调函数，传入参数total
            onProgress: null, //正在加载回调函数，传入参数currentIndex, total
            onComplete: null, //加载完毕回调函数，传入参数total
            abort: false//中断加载
        };
        if (config) {
            for (let i in config) {
                this.option[i] = config[i];
            }
        } else {
            console.log("resLoader param error...");
            return;
        }
        this.status = 0; //加载器的状态，0：未启动   1：正在加载   2：加载完毕
        this.total = this.option.resources.length || 0; //资源总数
        this.currentIndex = 0; //当前正在加载的资源索引
    }

    ResLoader.prototype.start = function () {
        this.status = 1;
        let _this = this;
        let baseUrl = this.option.baseUrl;
        for (let i = 0, l = this.option.resources.length; i < l; i++) {
            let r = this.option.resources[i], url = '';
            if (r.indexOf('http://') === 0 || r.indexOf('https://') === 0) {
                url = r;
            } else {
                url = baseUrl + r;
            }

            let reg = /\.mp3/;
            let reg1 = /\.mp4/;
            if (reg.test(url)) {
                let audio = new Audio();
                audio.src = url;
                audio.oncanplaythrough = function () {
                    _this.loaded();
                };
                audio.onerror = function () {
                    _this.loaded();
                };
            } else if (reg1.test(url)) {
                let video = document.createElement('video');
                video.src = url;
                video.oncanplaythrough = function () {
                    _this.loaded();
                };
                video.onerror = function () {
                    _this.loaded();
                };
            } else {
                let image = new Image();
                image.src = url;

                if (image && (!image.complete || image.width === 0)) {
                    image.onload = function () {
                        _this.loaded();
                    };
                    image.onerror = function () {
                        _this.loaded();
                    };
                } else {
                    _this.loaded();
                }
            }
        }
        if (isFunc(this.option.onStart)) {
            this.option.onStart(this.total);
        }
    };

    ResLoader.prototype.loaded = function () {
        if (isFunc(this.option.onProgress)) {
            this.option.onProgress(++this.currentIndex, this.total);
        }
        if (this.currentIndex >= this.total && !this.abort) {
            if (isFunc(this.option.onComplete)) {
                this.option.onComplete(this.total);
            }
        }
    };

    ResLoader.prototype.stop = function () {
        this.currentIndex = this.total;
        this.abort = true;

        if (isFunc(this.option.onComplete)) {
            this.option.onComplete(this.total);
        }
    };
    return ResLoader;
}));