<template>
  <div class="lessonModel S2" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="tips">{{$t('course.which_word_doesn_t_belong_here')}}</div>
        <div class="trans">{{trans}}</div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div v-if="op.ty !== '1'" class="option" :class="{active: i === currentOptionIndex }" @click="optionChooseHandle(i)" :key="i">
            <div class="deleteIcon"><svg t="1679301442937" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1458" width="24" height="24"><path d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128zM672 627.2c12.8 12.8 12.8 32 0 44.8s-32 12.8-44.8 0L512 556.8l-115.2 115.2c-12.8 12.8-32 12.8-44.8 0s-12.8-32 0-44.8L467.2 512 352 396.8C339.2 384 339.2 364.8 352 352s32-12.8 44.8 0L512 467.2l115.2-115.2c12.8-12.8 32-12.8 44.8 0s12.8 32 0 44.8L556.8 512 672 627.2z" p-id="1459" fill="#D7DADE"></path></svg></div>
            <div class="deleteWrap"></div>
            <div class="optionName">{{op.wd}}</div>
          </div>
          <div v-else class="option punc" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">{{sentence}}</div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        sentence: '',
        options: [],
        answerStr: '',
        currentOptionIndex: ''
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        let { id, tr, st, ops, wl } = this.lessonData

        for (let i = 0; i < ops.length; i ++) {
          let option = ops[i]
          let lastOption = ops[i - 1]

          if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastOption.wd)) {
            option.wd = this.capitalize(option.wd)
          }
        }

        this.id = id
        this.trans = tr
        this.sentence = st
        this.options = ops
        this.answerStr = wl
      },
      optionChooseHandle(index) {
        this.currentOptionIndex = index
        this.checkStatus = 'checkReady'
      },
      playHandle() {
        if (['correct', 'wrong'].includes(this.checkStatus)) {
          this.$refs.checkWrap.stopVideo()
          this.playAudio('s', this.id)
        }
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          let optionsStr = ''
          for (let i = 0; i < this.options.length; i ++) {
            let option = this.options[i]
            if (this.currentOptionIndex !== i) {
              optionsStr = optionsStr + option.id + ';'
            }
          }
  
          if (optionsStr === this.answerStr) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
          this.playHandle()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S2 {
    .lessonWrap {
      overflow: hidden;
      .optionsWrap {
        flex-wrap: wrap;
        .option {
          margin: 6px;
          margin-top: 20px;
          border-radius: 12px;
          overflow: unset;
          &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: -100vw;
            width: 200vw;
            height: 1px;
            background-color: #C4C9D8;
          }
          .optionName {
            padding: 12px 20px;
          }
          .deleteIcon {
            display: flex;
            position: absolute;
            left: -12px;
            top: -12px;
          }
          .deleteWrap {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            background: #5252572e;
            background-image: linear-gradient(to top right, transparent 48%, #FF1B1B 48%, #FF1B1B 52%, transparent 52%);
          }
          &.active {
            &:hover {
              cursor: unset;
              background: #FFFFFF;
            }
            .deleteIcon {
              display: none;
            }
            .deleteWrap {
              display: block;
              transform-origin: left top;
              animation: deleteWrapShow 0.3s;
            }
            @keyframes deleteWrapShow {
              0% {
                transform: scale(0);
              }
              100% {
                transform: scale(1);
              }
            }
          }
        }
        .punc {
          background: transparent;
          cursor: unset;
        }
      }
    }
  }
</style>