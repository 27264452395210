<template>
  <div class="checkWrap" :class="checkStatus">
    <div class="checkContent">
      <div class="leftWrap" v-if="['correct', 'wrong'].includes(checkStatus)">
        <div class="iconWrap">
          <img class="checkIcon" :src="require(`@/assets/images/courses/lesson/check/${checkStatus}.png`)">
          <div v-if="seriesCorrectNumber >= 2" class="numberWrap">
            <div class="seriesCorrectNumber">{{seriesCorrectNumber}}</div>
            <img class="numberIcon" src="@/assets/images/courses/lesson/check/correctNumberStar.png">
          </div>
        </div>
        <div class="contentWrap">
          <div class="sentence">
            <div class="audio" :class="{active: audioPlaying}"></div>
            <slot></slot>
          </div>
          <div class="translate">{{trans}}</div>
        </div>
      </div>

      <div class="leftWrap" v-else>
        <div class="iconWrap">
          <img class="checkIcon" src="@/assets/images/courses/lesson/check/default.png">
        </div>
      </div>

      <div class="rightWrap">
        <div v-if="['correct', 'wrong'].includes(checkStatus)" class="checkBtn" @click.stop="$emit('next')">{{$t('course.continue_txt')}}</div>
        <div v-else class="checkBtn" @click.stop="$emit('check')">{{$t('course.test_check')}}</div>

        <div v-show="videoWrapShow" v-if="['correct', 'wrong'].includes(checkStatus)" class="lessonVideoWrap" @click.stop="videoPlayHandle">
          <video :src="lessonVideoUrl" ref="lessonVideo" @play="playStatus = false" @pause="playStatus = true" @canplaythrough="videoWrapShow = true"></video>
          <img v-show="playStatus" class="playIcon" src="@/assets/images/clips/lesson/play.png">
        </div>
      </div>
    </div>
    <div class="mask" v-show="['correct', 'wrong'].includes(checkStatus)"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        playStatus: true,
        videoWrapShow: false
      }
    },
    props: ['trans', 'checkStatus', 'seriesCorrectNumber', 'audioPlaying', 'lessonVideoUrl'],
    methods: {
      videoPlayHandle() {
        this.$emit('stopAudio')
        this.$refs.lessonVideo.play()
      },
      stopVideo() {
        if (this.$refs.lessonVideo) {
          this.$refs.lessonVideo.pause()
          this.$refs.lessonVideo.currentTime = 0
        }
      }
    }
  }
</script>