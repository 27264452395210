<template>
  <div class="lessonModel S9" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="trans">{{trans}}</div>
        <div class="stems">
          <div class="audio" :class="{active: audioPlaying && !['correct', 'wrong'].includes(checkStatus)}"></div>
          <template v-for="(stem, i) in stems">
            <template v-if="stem.inputIndex >= 0">
              <div class="input" :class="{active: stem.inputIndex === inputIndex, correct: stem.correct, wrong: stem.wrong}" @click.stop="focusInputHandle(stem)" :key="i">
                <div class="inputChar">{{inputChars[stem.inputIndex]}}</div>
                <div class="caret"></div>
              </div>
            </template>
            <template v-else>
              <div class="stemWrap" :key="i">
                <div class="stem">
                  {{stem.wd}}
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <div class="answerWrap">
        <div class="optionsWrap">
          <template v-for="(op, i) in options[inputIndex]">
            <div class="option" :ref="'option' + i" :class="{remind: op.remind}" @click.stop="optionChooseHandle(op, i)" :key="i">
              <div class="optionName">{{op}}</div>
            </div>
          </template>
        </div>

        <div class="divider"></div>

        <div class="operationWrap">
          <div class="leftWrap"></div>
          <div class="rightWrap">
            <div class="iconWrap eyeIcon" :class="{active: eyeActive}" @click.stop="eyeHandle" v-show="eyeVisible"><svg t="1679467135642" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3885" id="mx_n_1679467135643" width="34" height="34"><path d="M512 192c156.448 0 296.021333 98.730667 418.410667 291.605333a52.938667 52.938667 0 0 1 0 56.789334C808.021333 733.269333 668.448 832 512 832c-156.448 0-296.021333-98.730667-418.410667-291.605333a52.938667 52.938667 0 0 1 0-56.789334C215.978667 290.730667 355.552 192 512 192z m0 128c-106.037333 0-192 85.962667-192 192s85.962667 192 192 192 192-85.962667 192-192-85.962667-192-192-192z m0 320c70.688 0 128-57.312 128-128s-57.312-128-128-128-128 57.312-128 128 57.312 128 128 128z" fill="#004E94" p-id="3886"></path></svg></div>
            <div class="iconWrap backspaceIcon" @click.stop="backspaceHandle"><svg t="1681973458713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3724" width="28" height="28"><path d="M950.637714 219.538286v584.923428H393.508571l-290.596571-291.547428 292.425143-293.376h555.264zM1023.634286 146.285714H365.421714L0 512.914286 363.593143 877.714286H1024V146.285714h-0.365714z" fill="#004E94" p-id="3725"></path><path d="M534.308571 318.171429l-51.565714 51.565714 336.091429 336.091428 51.565714-51.565714z" fill="#004E94" p-id="3726"></path><path d="M818.834286 318.171429l-336.091429 336.091428 51.565714 51.565714 336.091429-336.091428z" fill="#004E94" p-id="3727"></path></svg></div>
          </div>
        </div>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">
        <template v-for="(word, i) in words">
          <div class="word" :class="{ underline: word.underline, wrong: word.wrong }" :key="i">
            {{word.wd}}
          </div>
        </template>
      </div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        stems: [],
        words: [],
        options: [],
        answerChars: [],
        inputChars: [],
        inputIndex: 0,
        eyeActive: false
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    computed: {
      eyeVisible() {
        return !!this.options?.[this.inputIndex]?.length
      }
    },
    watch: {
      inputChars: {
        handler(val) {
          if (val.join('').length === this.answerChars.join('').length) {
            this.checkStatus = 'checkReady'
          } else {
            this.checkStatus = 'default'
          }

          if (!this.options[this.inputIndex].length) {
            if (this.inputIndex !== this.options.length - 1) {
              this.inputIndex ++
            }
          }
        },
        deep: true
      }
    },
    methods: {
      init() {
        let { id, tr, op, ops, wls } = this.lessonData
        let stems = []
        let options = []
        let answerChars = []
        let optionIDs = op.split(';').filter(item => item !== '')

        for (let word of wls) {
          let inputIndex = optionIDs.indexOf(word.id)
          if (inputIndex >= 0) {
            word.inputIndex = inputIndex
          }
          stems.push(word)
        }

        for (let word of ops) {
          let optionChars = word.wd.split('').sort(() => Math.random() - 0.5)
          options.push(optionChars)
          answerChars.push(word.wd)
        }

        this.id = id
        this.trans = tr
        this.stems = stems
        this.words = wls
        this.options = options
        this.answerChars = answerChars
        this.inputChars = new Array(ops.length).fill('')
      },
      playHandle() {
        this.$refs.checkWrap.stopVideo()
        this.playAudio('s', this.id)
      },
      focusInputHandle(stem) {
        this.inputIndex = stem.inputIndex
      },
      optionChooseHandle(op, index) {
        let inputChar = this.inputChars[this.inputIndex]
        let currentOption = this.options[this.inputIndex]
        this.$set(this.inputChars, this.inputIndex, inputChar + op)
        currentOption.splice(index, 1)
      },
      backspaceHandle() {
        let inputChar = this.inputChars[this.inputIndex]
        if (inputChar.length) {
          let currentOption = this.options[this.inputIndex]
          let lastChar = inputChar.slice(-1)
          inputChar = inputChar.slice(0, -1)
          this.$set(this.inputChars, this.inputIndex, inputChar)
          currentOption.push(lastChar)
        }
      },
      eyeHandle() {
        let that = this
        let answerChar = this.answerChars[this.inputIndex]
        let inputChar = this.inputChars[this.inputIndex]
        let currentOption = this.options[this.inputIndex]
        let currentStem = this.stems.find(word => word.inputIndex === this.inputIndex)
        if (answerChar.indexOf(inputChar) === 0) {
          let nextChar = answerChar.charAt(inputChar.length)
          for (let i = 0; i < currentOption.length; i ++) {
            let option = currentOption[i]
            if (option === nextChar) {
              let optionDom = this.$refs['option' + i][0]
              optionDom.classList.add('remind')
              setTimeout(() => {
                optionDom.classList.remove('remind')
              }, 1000)
              return
            }
          }
        } else {
          this.eyeActive = true
          currentStem.wrong = true
          setTimeout(() => {
            that.eyeActive = false
            currentStem.wrong = false
          }, 1000)
        }
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          for (let i = 0; i < this.words.length; i ++) {
            let word = this.words[i]
            let lastWord = this.words[i - 1]
            if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastWord.wd)) {
              word.wd = this.capitalize(word.wd)
            }
            if (word.inputIndex >= 0) {
              word.underline = true
              let inputWord = this.inputChars[word.inputIndex]
              if (inputWord.toLowerCase() !== word.wd.toLowerCase()) {
                word.wrong = true
              } else {
                word.correct = true
              }
            }
          }

          if (this.inputChars.join('') === this.answerChars.join('')) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S9 {
    .lessonWrap {
      .questionWrap {
        margin-bottom: 60px;
        .trans {
          font-weight: 400;
          font-size: 18px;
          color: #9090A2;
        }
        .stems {
          margin-top: 50px;
          .input {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 3px;
            padding: 4px;
            min-width: 60px;
            height: 32px;
            font-size: 22px;
            border-bottom: 1px solid #A2A2A2;
            box-sizing: border-box;
            cursor: pointer;
            &.active {
              border-bottom: 2px solid #004E94;
              .caret {
                width: 2px;
                background: #004E94;
                height: 100%;
                animation: caret 1s infinite steps(1, start);
                @keyframes caret {
                  0%, 100% {
                    opacity: 0;
                  }
                  50% {
                    opacity: 1;
                  }
                }
              }
            }
            &.correct {
              border-bottom: 2px solid #2BC58A;
              color: #2BC58A;
              .caret {
                display: none;
              }
            }
            &.wrong {
              border-bottom: 2px solid #EE5151;
              color: #EE5151;
              .caret {
                display: none;
              }
            }
          }
        }
      }
      .answerWrap {
        box-sizing: border-box;
        padding: 20px;
        padding-bottom: 12px;
        width: 590px;
        background: #E0E9F6;
        border-radius: 20px;
        .divider {
          margin: 20px 0;
          margin-bottom: 12px;
          height: 1px;
        }
        .optionsWrap {
          justify-content: flex-start;
          align-content: start;
          overflow: auto;
          min-height: 160px;
          max-height: 200px;
          .option {
            margin: 5px;
            padding: 8px;
            min-width: 45px;
            min-height: 43px;
            border-radius: 10px;
            font-size: 18px;
            &:hover {
              background: #C3D6F8;
            }
            &.active {
              visibility: hidden;
              cursor: default;
            }
            &.remind {
              animation: remind 1s ease;
              @keyframes remind {
                50% {
                  background: #004E94;
                  color: #FFFFFF;
                }
              }
            }
          }
        }
        .operationWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 34px;
          .leftWrap, .rightWrap {
            display: flex;
            .iconWrap {
              display: flex;
              align-items: center;
              cursor: pointer;
              &.eyeIcon {
                margin-right: 24px;
                &.active {
                  animation: eyeActive 1s linear;
                  @keyframes eyeActive {
                    10% {
                      transform: translateX(8px);
                    }
                    20% {
                      transform: translateX(-6px);
                    }
                    30% {
                      transform: translateX(5px);
                    }
                    40% {
                      transform: translateX(-5px);
                    }
                    50%, 100% {
                      transform: translateX(0px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .S9 {
      .lessonWrap {
        .answerWrap {
          width: 100%;
        }
      }
    }
  }
</style>