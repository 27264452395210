<template>
  <div class="testoutFailed">
    <img class="testoutFailedIcon" src="@/assets/images/courses/lesson/testoutFailed.png" alt="testoutFailedIcon">
    <div class="testoutFailedText">{{$t('course.sorry_let_s_go_through_the_lessons_and_try_again_later')}}</div>
    <div class="okBtn" @click="okClickHandle">{{$t('main.ok')}}</div>
  </div>
</template>

<script>
  export default {
    methods: {
      okClickHandle() {
        this.$router.push('/courses')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .testoutFailed {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &Icon {
      width: 258px;
    }
    &Text {
      margin-top: 60px;
      font-weight: 900;
      font-size: 20px;
      color: #004E94;
    }
    .okBtn {
      margin-top: 100px;
      width: 300px;
      height: 46px;
      line-height: 46px;
      border-radius: 46px;
      background: #004E94;
      font-weight: 700;
      font-size: 20px;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #0A4172;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .testoutFailed {
      &Text {
        padding: 0 10px;
      }
    }
  }
</style>