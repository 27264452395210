<template>
  <div class="lessonModel W3" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="audioPlayer" :class="{active: audioPlaying && !['correct', 'wrong'].includes(checkStatus)}"></div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option image" :class="[{active: op.id === currentOptionID}, checkStatus]" @click.stop="optionChooseHandle(op)" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">{{word}}</div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        trans: '',
        word: '',
        options: [],
        answerID: '',
        currentOptionID: ''
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    methods: {
      init() {
        let { ops, an, tr, wd } = this.lessonData
        this.options = ops.sort(() => Math.random() - 0.5)
        this.trans = tr
        this.word = wd
        this.answerID = an
      },
      optionChooseHandle(op) {
        this.currentOptionID = op.id
        this.checkStatus = 'checkReady'
      },
      playHandle() {
        this.$refs.checkWrap.stopVideo()
        this.playAudio('w', this.answerID)
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          if (this.currentOptionID === this.answerID) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .W3 {
    .optionsWrap {
      .option {
        &.image {
          width: 152px;
          height: 165px;
          .optionName {
            padding: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.active {
            .optionName {
              padding: 0;
            }
          }
        }
      }
    }
  }
</style>