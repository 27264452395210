<template>
  <div class="lessonModel S5" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="sentenceWrap">
          <div class="audio" :class="{active: audioPlaying && !['correct', 'wrong'].includes(checkStatus)}"></div>
          <div class="trans">{{trans}}</div>
        </div>
        <div class="stems">
          <template v-for="(stem, i) in stems">
            <div class="stem" @click.stop="stemChooseHandle(stem, i)" :key="i">
              {{stem.wd}}
            </div>
          </template>
        </div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option word" :class="{active: op.active}" @click.stop="optionChooseHandle(op)" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">
        <template v-for="(word, i) in words">
          <div class="word" :class="{ underline: word.underline, wrong: word.wrong }" :key="i">
            {{word.wd}}
          </div>
        </template>
      </div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        stems: [],
        words: [],
        options: [],
        answerIDs: []
      }
    },
    mounted() {
      this.init()
      this.playHandle()
    },
    watch: {
      stems(val) {
        if (val.length) {
          this.checkStatus = 'checkReady'
        } else {
          this.checkStatus = 'default'
        }
      }
    },
    methods: {
      init() {
        let { id, tr, ops, wls, an } = this.lessonData
        let options = []
        let answers = an.split('!@@@!')

        for (let answer of answers) {
          let answerIDs = answer.split(';').filter(item => item !== '')
          this.answerIDs.push(answerIDs)
        }

        for (let word of ops) {
          if (word.ty !== '1') {
            options.push(word)
          }
        }

        this.id = id
        this.trans = tr
        this.words = wls
        this.options = options.sort(() => Math.random() - 0.5)
      },
      optionChooseHandle(op) {
        if (!op.active) {
          op.active = true
          this.stems.push(op)
          this.playAudio('w', op.id)
        }
      },
      stemChooseHandle(stem, index) {
        stem.active = false
        this.stems.splice(index, 1)
      },
      playHandle() {
        this.$refs.checkWrap.stopVideo()
        this.playAudio('s', this.id)
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          let chooseIDs = []
          for (let word of this.stems) {
            chooseIDs.push(word.id)
          }

          let words = this.words.filter(item => item.ty !== '1')
          for (let i = 0; i < words.length; i ++) {
            let stem = this.stems[i]
            let word = words[i]
            if (stem) {
              if (stem.wd.toLowerCase() !== word.wd.toLowerCase()) {
                word.wrong = true
              }
            } else {
              word.wrong = true
            }
          }
          for (let i = 0; i < this.words.length; i ++) {
            let word = this.words[i]
            let lastWord = this.words[i - 1]
            if (i === 0 || ['.', '!', '¡', '?', '¿'].includes(lastWord.wd)) {
              word.wd = this.capitalize(word.wd)
            }
          }

          for (let answerIDs of this.answerIDs) {
            if (chooseIDs.join('') === answerIDs.join('')) {
              this.correctHandle()
              return
            }
          }
          this.wrongHandle()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S5 {
    .lessonWrap {
      .questionWrap {
        width: 100%;
        margin-bottom: 50px;
        .sentenceWrap {
          display: flex;
          align-items: center;
          .audioPlayer {
            margin-right: 10px;
          }
        }
        .stems {
          margin-top: 80px;
          padding-bottom: 4px;
          border-bottom: 1px solid #C4C9D8;
          min-width: 600px;
          max-width: 100%;
          min-height: 60px;
          .stem {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 50px;
            min-height: 50px;
            box-shadow: 0 2px 14px 0 #00000005;
            border-radius: 12px;
            padding: 0 10px;
            margin: 5px;
            background: #FFFFFF;
            font-weight: 500;
            font-size: 20px;
            color: #2F2F4D;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: #DCE4F2;
            }
          }
        }
      }
      .optionsWrap {
        .option {
          min-width: 50px;
          min-height: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .S5 {
      .lessonWrap {
        .questionWrap {
          .stems {
            margin-top: 50px;
            min-width: 100%;
          }
        }
      }
    }
  }
</style>