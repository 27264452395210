<template>
  <div class="lessonModel S1" @click="playHandle">
    <div class="lessonWrap">
      <div class="questionWrap">
        <div class="trans">{{trans}}</div>
      </div>

      <div class="optionsWrap">
        <template v-for="(op, i) in options">
          <div class="option sentence" :class="[{active: op.id === currentOptionID}, checkStatus]" @click="optionChooseHandle(op)" :key="i">
            <div class="optionName">{{op.wd}}</div>
          </div>
        </template>
      </div>
    </div>

    <check-wrap
      :trans="trans"
      :checkStatus="checkStatus"
      :seriesCorrectNumber="seriesCorrectNumber"
      :audioPlaying="audioPlaying"
      :lessonVideoUrl="lessonVideoUrl"
      ref="checkWrap"
      @check="checkHandle"
      @next="nextHandle"
      @stopAudio="audio.stop()">
      <div class="words">{{sentence}}</div>
    </check-wrap>
  </div>
</template>

<script>
  import modelMixins from '@/mixins/coursesModelMixins'

  export default {
    mixins: [ modelMixins ],
    data() {
      return {
        id: '',
        trans: '',
        sentence: '',
        options: [],
        answerID: '',
        currentOptionID: ''
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        let { id, tr, st, ops, an } = this.lessonData
        this.id = id
        this.trans = tr
        this.sentence = st
        this.options = ops.sort(() => Math.random() - 0.5)
        this.answerID = an
      },
      optionChooseHandle(op) {
        this.currentOptionID = op.id
        this.checkStatus = 'checkReady'
      },
      playHandle() {
        if (['correct', 'wrong'].includes(this.checkStatus)) {
          this.$refs.checkWrap.stopVideo()
          this.playAudio('s', this.id)
        }
      },
      checkHandle() {
        if (this.checkStatus === 'checkReady') {
          if (String(this.currentOptionID) === this.answerID) {
            this.correctHandle()
          } else {
            this.wrongHandle()
          }
          this.playHandle()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .S1 {
    .lessonWrap {
      .questionWrap {
        margin-bottom: 50px;
      }
    }
  }
</style>